import React from "react";
import { motion, useInView } from "framer-motion";
import "./Home.scss"; // Import your custom CSS for styling

import About from "../../Pages/About/About";
import Ourservices from "../../Pages/Ourservices/Ourservices";
import dummyimg from '../../Pages/images/transport.jpg'

import homepagevideo from "../../Pages/images/videoteshome.mp4";


const Home = () => {

  // Define the animation variants
  const baseVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };

  const leftVariants = {
    hidden: { opacity: 0, x: -100 }, // Start off-screen to the left
    visible: { opacity: 1, x: 0 }, // Move to its original position
  };

  const Section = ({ children, isLeft }) => {
    const ref = React.useRef(null);
    const isInView = useInView(ref, { once: false });

    return (
      <motion.div
        ref={ref}
        initial="hidden"
        animate={isInView ? "visible" : "hidden"}
        variants={isLeft ? leftVariants : baseVariants}
        transition={{ duration: 1.25, ease: "easeInOut" }}
        className="scroll-section"
      >
        {children}
      </motion.div>
    );
  };

  return (
    <>
   
        {/* <header className="hero-section">
          <Carousel className="carouselheight" interval={3000} controls={false} indicators={false}>
            Container Sale Slide
            <Carousel.Item>
              <div className="hero-content">
                <h1>Premium Container </h1>
                <p>
                  Explore our premium shipping containers for Buy & Rent, perfect for
                  various commercial and residential uses.
                </p>
                <div className="mt-3 ">
                  <a href="#services" className="cta-button">
                    Explore Our Containers
                  </a>
                </div>
              </div>
            </Carousel.Item>

            Container Rent Slide
            <Carousel.Item>
              <div className="hero-content">
                <h1>Container Rent</h1>
                <p>
                  Rent high-quality shipping containers for short-term or
                  long-term use at competitive rates.
                </p>
                <div className="mt-3">
                  <a href="#services" className="cta-button">
                    Rent Containers Now
                  </a>
                </div>
              </div>
            </Carousel.Item>

            Container Tracking Slide
            <Carousel.Item>
              <div className="hero-content">
                <h1>Container Tracking</h1>
                <p>
                  Track your containers in real-time and ensure secure delivery to
                  their destination.
                </p>
                <div className="mt-3">
                  <a href="#tracking" className="cta-button">
                    Track Your Container
                  </a>
                </div>
              </div>
            </Carousel.Item>
          </Carousel>
        </header> */}
<div className=" container-fuild thumbnail-video-container ">
  <video
    src={homepagevideo}
    className="thumbnail-video"
    loop
    muted
    autoPlay
    playsInline // Ensures autoplay on mobile devices (iOS/Safari)

  />
</div>


      <About />

      {/* Why Choose Us section with left animation */}
      <Section isLeft>
        <div className=" bgimage">

          <div>
          <h2 className="choose">Why Choose Our Containers?</h2>
        
       

          
            <div className="scrollable-content">
          {/* <p className="choose">Why Choose Our Containers?</p> */}

              <h5 className=" choose justify-center text-center">Affordable Pricing</h5>
              <p className="text-center">
                We offer competitive pricing without compromising on quality.
              </p>
              </div>
            </div>
          </div>
  
      </Section>

      {/* Premium Rental Services Section */}
      <Section>
        <motion.div
          className="rental-info-container"
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }} // This section doesn't need separate visibility logic
          transition={{ duration: 2.8, ease: "easeOut", delay: 2.5 }} // Added delay here
        >
          <motion.div
            className="rental-image col-6"
            initial={{ x: -100, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }} // Animation triggers with section visibility
            transition={{ duration: 0.8, delay: 0.6 }} // Added delay here
          >
            <img src={dummyimg} className="hide" alt="Rental Services" />

         
          </motion.div>

          <motion.div
            className="rental-details"
            initial={{ x: 100, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }} // Animation triggers with section visibility
            transition={{ duration: 0.9, delay: 0.8 }} // Added delay here
          >
            <div className="rental-content">
              <h2>Explore Our Premium Container & Rental/Lease  Services</h2>
              <p>
                We offer a wide range of rental services to meet your needs, from home essentials to luxury items. Whether you're looking to rent furniture, appliances, or vehicles, we've got you covered with flexible options and competitive rates.
              </p>
              <motion.button
                className="explore-more-btn"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                
              >
                <a href="/Our-Container">
                Explore More</a>
              </motion.button>
            </div>

            <div className="features-header">
              <h3>WHY CHOOSE US?</h3>
              <div className="col-12 row">
                <div className="col-6">
              <ul>
              <li>High-quality products</li>
                <li>Wide variety of options</li>
                <li>Competitive pricing</li>
                <li>Excellent customer support</li>
              </ul>
              </div>
              <div className="col-6">
              <ul>
                <li>Flexible rental periods</li>
                <li>Wide variety of products</li>
                <li>Fixed Income</li>
                <li>Passive Income</li>
              </ul>
              </div>
              </div>
            </div>
          </motion.div>
        </motion.div>
      </Section>
      {/* <LiveMarket/> */}
      <Section>
        <motion.div
          className="mobilereesponsive"
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }} // This section doesn't need separate visibility logic
          transition={{ duration: 2.8, ease: "easeOut", delay: 2.5 }} // Added delay here
        >
          <motion.div
            className=""
            initial={{ x: -100, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }} // Animation triggers with section visibility
            transition={{ duration: 0.8, delay: 0.6 }} // Added delay here
          >
            <img src={dummyimg} className="hide" alt="Rental Services" />
          </motion.div>

          <motion.div
            className="rental-details"
            initial={{ x: 100, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }} // Animation triggers with section visibility
            transition={{ duration: 0.9, delay: 0.8 }} // Added delay here
          >
            <div className="rental-content">
              <h2>Explore Our Premium Container & Rental  Services</h2>
              <p>
                We offer a wide range of rental services to meet your needs, from home essentials to luxury items. Whether you're looking to rent furniture, appliances, or vehicles, we've got you covered with flexible options and competitive rates.
              </p>
              <motion.button
                className="explore-more-btn"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
               <a href="/Our-Container">
               Explore More</a>
              </motion.button>
            </div>

            <div className="features-header">
              <h3>WHY CHOOSE US?</h3>
              <div className="col-12 row">
                <div className="col-6">
              <ul>
              <li>High-quality products</li>
                <li>Wide variety of options</li>
                <li>Competitive pricing</li>
                <li>Excellent customer support</li>
              </ul>
              </div>
              <div className="col-6">
              <ul>
                <li>Flexible rental periods</li>
                <li>Wide variety of products</li>
                <li>Fixed Income</li>
                <li>Passive Income</li>
              </ul>
              </div>
              </div>
            </div>
          </motion.div>
        </motion.div>
      </Section>

      

    

{/* <StockTicker/> */}
      

      {/* Our Services */}

      <div id="services">
      <Ourservices />
      </div>
{/* 
      <Client />
      <ExpertTeam /> */}

     
    </>
  );
};

export default Home;
