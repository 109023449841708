import React from "react";
import FAQ from "../../Components/FAQ/FAQ";
import { motion } from "framer-motion";

import "../Howtowork/Howtowork.css";
import dummyimg from "../../Pages/images/transport.jpg";
import Carousel from "react-bootstrap/Carousel";
import HowtoCarousel from "./HowtoCarousel";
import Tabscomponent from "./Tabs";
import Marketnew from "../../Components/LIveMarket/Livemarket";
export default function Howtowork() {
  const containerVariants = {
    hidden: { opacity: 0, x: -50 },
    visible: { opacity: 1, x: 0 },
  };
  return (
    <div>
      <section>
        <header className="hero-sectionhow">
        <div className="hero-contentwork">
                <h1>How to work</h1>
                <p>
                  Explore our premium shipping containers for Buy/Rent and Lease, perfect for
                  various commercial and residential uses.
                </p>
                {/* <div className="mt-3 ">
                  <a href="#services" className="cta-button">
                    Explore Our Containers
                  </a>
                </div> */}
              </div>
        </header>
      </section>
      {/* <HowtoCarousel/> */}

      <Tabscomponent />

      {/* <FAQ/> */}
    </div>
  );
}
