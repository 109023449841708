import axios from "axios";
import React, { useRef, useState } from "react";
import SignatureCanvas from "react-signature-canvas";
import { Button, Spinner } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const SignatureCanvasComponent = () => {
  const sigCanvas = useRef(null);
  const [signatureData, setSignatureData] = useState(null);
  const [uploadError, setUploadError] = useState("");
  const [selectedMethod, setSelectedMethod] = useState("canvas");
  const [isLoading, setIsLoading] = useState(false);
  const [isSignatureSaved, setIsSignatureSaved] = useState(false); // New state

  const orderid = localStorage.getItem("order_id");
  console.log(orderid, "orderidorderid");

  const clearSignature = () => {
    if (sigCanvas.current) {
      sigCanvas.current.clear();
      setSignatureData(null);
      setUploadError("");
      setIsSignatureSaved(false); // Allow signing again
    }
  };

  const handleCanvasSave = () => {
    if (selectedMethod === "canvas" && sigCanvas.current) {
      const dataURL = sigCanvas.current
        .getTrimmedCanvas()
        .toDataURL("image/png");
      setSignatureData(dataURL);
      setUploadError("");
      setIsSignatureSaved(true); // Set signature as saved
    }
  };

  const handleFileUpload = (event) => {
    if (selectedMethod === "upload") {
      const file = event.target.files[0];
      if (!file) return;
      const reader = new FileReader();

      reader.onloadend = () => {
        setSignatureData(reader.result);
        setUploadError("");
        if (sigCanvas.current) sigCanvas.current.clear();
        setIsSignatureSaved(true); // Set signature as saved
      };

      reader.readAsDataURL(file);
    }
  };

  const saveSignature = () => {
    if (!signatureData) {
      setUploadError("Please draw or upload a signature before saving.");
      return;
    }
    setUploadError("");
    setIsLoading(true);
    uploadSignature();
  };

  const uploadSignature = async () => {
    try {
      const formData = new FormData();
      formData.append("order_id", orderid);

      // Convert base64 to Blob
      const blob = await fetch(signatureData).then((res) => res.blob());
      formData.append("sign", blob, "signature.png"); // specify the filename

      const response = await axios.post(
        "https://lunarsenterprises.com:4002/brookscontainer/container/form/order/sign",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      const result = response.data.result;
      const { message } = response.data;
      toast.success(message || "Signature uploaded successfully.");

      // Check if the result is true and refresh after 8 seconds
      if (result === true) {
        setTimeout(() => {
          window.location.reload();
        }, 8000);
      }
    } catch (error) {
      const errorMessage =
        error.response?.data?.message || "Error uploading signature.";
      toast.error(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  const handleResign = () => {
    setSignatureData(null); // Clear the current signature
    setIsSignatureSaved(false); // Allow signing again
    if (sigCanvas.current) {
      sigCanvas.current.clear(); // Clear the canvas
    }
  };

  return (
    <div className="mt-3 mb-5">
      <ToastContainer />
      <div>
        <label>
          <input
            type="radio"
            value="canvas"
            checked={selectedMethod === "canvas"}
            onChange={() => {
              if (!isSignatureSaved) {
                // Allow change only if not saved
                setSelectedMethod("canvas");
                setSignatureData(null);
                if (sigCanvas.current) sigCanvas.current.clear();
              }
            }}
            disabled={isSignatureSaved} // Disable if signature is saved
          />
            {" "} Draw Signature
        </label>
        <label style={{ marginLeft: "20px" }}>
          <input
            type="radio"
            value="upload"
            checked={selectedMethod === "upload"}
            onChange={() => {
              if (!isSignatureSaved) {
                // Allow change only if not saved
                setSelectedMethod("upload");
                setSignatureData(null);
                if (sigCanvas.current) sigCanvas.current.clear();
              }
            }}
            disabled={isSignatureSaved} // Disable if signature is saved
          />
         {" "} Upload Image
        </label>
      </div>

      {selectedMethod === "canvas" && (
        <div className="mt-3">
          <SignatureCanvas
            ref={sigCanvas}
            backgroundColor="white"
            penColor="black"
            canvasProps={{ width: 400, height: 200, className: "sigCanvas" }}
            disabled={isSignatureSaved} // Disable canvas if signature is saved
          />
          <button
            className="mt-3"
            onClick={clearSignature}
            disabled={isSignatureSaved}
          >
            Clear
          </button>
          <button
            className="mt-3"
            onClick={handleCanvasSave}
            disabled={isSignatureSaved}
          >
            Save Drawing
          </button>

          {signatureData &&
            isSignatureSaved && ( // Show preview if signature is saved
              <div>
                <h6>Preview:</h6>
                <img
                  src={signatureData}
                  alt="Signature"
                  style={{ width: "70%", height: "250px" }}
                />
                <button className="mt-3" onClick={handleResign}>
                  Re-sign
                </button>
              </div>
            )}
        </div>
      )}

      {selectedMethod === "upload" && (
        <div style={{ marginTop: "20px" }}>
          <input
            type="file"
            accept="image/*"
            onChange={handleFileUpload}
            style={{ marginBottom: "10px" }}
            disabled={isSignatureSaved} // Disable file input if signature is saved
          />
          <span>Upload Signature</span>
          <p style={{ color: "red", fontSize: "14px", marginTop: "5px" }}>
            * Please upload a clear image of your signature on a white blank
            paper.
            <br />* Ensure the picture is well-lit and focused for best results.
          </p>

          {signatureData &&
            isSignatureSaved && ( // Show preview if signature is saved
              <div>
                <h6>Preview:</h6>
                <img
                  src={signatureData}
                  alt="Signature"
                  style={{ width: "70%", height: "250px" }}
                />
                <button className="mt-3" onClick={handleResign}>
                  Re-sign
                </button>
              </div>
            )}
        </div>
      )}

      <Button className="mt-3" onClick={saveSignature} disabled={isLoading}>
        {isLoading ? (
          <>
            <Spinner animation="grow" role="status" className="me-2" />
            <span>Submitting...</span>
          </>
        ) : (
          "Upload Signature"
        )}
      </Button>

      {uploadError && <p style={{ color: "red" }}>{uploadError}</p>}
    </div>
  );
};

export default SignatureCanvasComponent;
