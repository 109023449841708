import React from 'react';
import '../Ourservices/Ourservices.scss';
import dummyimg from '../images/transport.jpg'

import image1 from '../images/20ft-high-cube-new-1.png'
import image2 from '../images/reefer 40.jpg'
import image3 from '../images/dry container 20.jpg'




function Ourservices() {
  return (
    <>
   {/* Our Services */}
<section>
  <section id="services" className="services">
    <h2>Our Services</h2>
    <div className="service-list">
      <div className="service-card">
        <img src={image1} alt="Container Sales" />
        <div className="service-content">
          <h3>Short Term Lease</h3>
          <p>Affordable, short-term rental options for temporary storage or transit needs.</p>
        </div>
      </div>
      <div className="service-card">
        <img src={image2} alt="Container Rentals" />
        <div className="service-content">
          <h3>Long Term Lease</h3>
          <p>Flexible, long-term leasing for businesses seeking extended storage solutions.</p>
        </div>
      </div>
      <div className="service-card">
        <img src={image3} alt="Custom Modifications" />
        <div className="service-content">
          <h3>Storage Services</h3>
          <p>Secure, on-demand storage services to suit various lease terms and locations.</p>
        </div>
      </div>
    </div>
  </section>
</section>
</>
  );
}

export default Ourservices;
