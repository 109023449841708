import React, { useState } from "react";
import { useForm } from "react-hook-form";
import Select from "react-select";
import "./Contact.scss";
import axios from "axios";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Col, Row, Spinner } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Contact = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const [selectedOption, setSelectedOption] = useState(null);
  const [phone, setPhone] = useState("");
  const [phoneError, setPhoneError] = useState(false);
  const [loading, setLoading] = useState(false);

  const options = [
    { value: "Buy and Rent a Container", label: "Buy and Rent a Container" },
    { value: "Lease a Container", label: "Lease a Container" },
    { value: "General Inquiry", label: "General Inquiry" },
  ];

  const onSubmit = async (data) => {
    if (!selectedOption || !phone || phone.length < 8) {
      setPhoneError(!phone || phone.length < 10);
      alert("Please fill all required fields.");
      return;
    }

    const formData = {
      name: data.name,
      phno: phone,
      email: data.email,
      subject: selectedOption.value,
      message: data.message,
    };

    try {
      setLoading(true);
      const response = await axios.post(
        "https://lunarsenterprises.com:4002/brookscontainer/contact-us",
        formData
      );

      const { message } = response.data;
      toast.success(
        message || "Thank you! Our team will connect with you soon."
      );
      if (response.data.status) {
        reset();
        setSelectedOption(null);
        setPhone("");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      toast.error("There was an error submitting the form. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="hero-sectionhowcont ">
      <ToastContainer />

      <div className=" contact-container">
        <h1 className=" text-center company-name mb-4">Get in Touch</h1>
        <Row>
          <Col className="col-md-6 align-items-center justify-content-center d-flex">
            <div className="contact-details-container mt-4">
              <h2 className="company-name">Brooks Container Services</h2>

              <p className="company-description">
                <i className="fas fa-info-circle info-icon"></i>
                {""}
                {""} We’re here to help with your container needs. Whether
                you’re looking to buy, rent, or simply have questions, our team
                is ready to assist. Fill out the form below, and we’ll get back
                to you as soon as possible.
              </p>

              <div className="contact-info">
                <p className="company-address">
                  <i className="fas fa-map-marker-alt address-icon me-1"></i>
                  {""}
                  {""}
                
                  Address Building A2 IFZA Business Park - Dubai Silicon Oasis -
                  Dubai
                </p>
                <p className="company-email">
                  <i className="fas fa-envelope email-icon"></i>
                  {""}
                  {""}
                  <a href="mailto:info@brookscontainer.com">
                    {""}
                    {""} info@brookscontainer.com
                  </a>
                </p>

                <p className="company-email">
                  <i className="fas fa-envelope email-icon"></i>
                  {""}
                  {""}
                  <a href="mailto:contact@brookscontainer.com">
                    {""}
                    {""} contact@brookscontainer.com
                  </a>
                </p>
              </div>
            </div>
          </Col>
          <Col className=" col-md-6">
            <form onSubmit={handleSubmit(onSubmit)} className="contact-form">
              <div className="form-group">
                <label className="Email" htmlFor="name">
                  Name
                </label>
                <input
                  id="name"
                  className={`form-input ${errors.name ? "input-error" : ""}`}
                  {...register("name", {
                    required: "Name is required (min 3 characters).",
                    minLength: {
                      value: 3,
                      message: "Minimum length is 3 characters.",
                    },
                    maxLength: {
                      value: 100,
                      message: "Maximum length is 100 characters.",
                    },
                    pattern: {
                      value: /^[A-Za-z\s]+$/,
                      message: "Only letters and spaces are allowed.",
                    },
                  })}
                  placeholder="Enter your name"
                />
                {errors.name && (
                  <p className="error-message">{errors.name.message}</p>
                )}
              </div>

              <div className="form-group">
                <label className="Email" htmlFor="phno">
                  Phone Number
                </label>
                <PhoneInput
                  country={"us"}
                  value={phone}
                  onChange={setPhone}
                  inputStyle={{ width: "100%" }}
                />
                {phoneError && (
                  <p className="error-message">
                    Please provide a valid phone number.
                  </p>
                )}
              </div>

              <div className="form-group">
                <label className="Email" htmlFor="email">
                  Email
                </label>
                <input
                  id="email"
                  type="email"
                  className={`form-input ${errors.email ? "input-error" : ""}`}
                  {...register("email", {
                    required: "Valid email is required.",
                    pattern: {
                      value: /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/,
                      message: "Invalid email format.",
                    },
                  })}
                  placeholder="Enter your email"
                />
                {errors.email && (
                  <p className="error-message">{errors.email.message}</p>
                )}
              </div>

              <div className="form-group">
                <label className="Email" htmlFor="inquiryType">
                  Inquiry Type
                </label>
                <Select
                  id="inquiryType"
                  className="select-dropdown"
                  options={options}
                  value={selectedOption}
                  onChange={setSelectedOption}
                  placeholder="Select an inquiry type"
                />
                {!selectedOption && (
                  <p className="error-message">
                    Please select an inquiry type.
                  </p>
                )}
              </div>

              <div className="form-group">
                <label className="Email" htmlFor="message">
                  Message
                </label>
                <textarea
                  id="message"
                  className="form-input"
                  placeholder="Write your message"
                  rows="4"
                  {...register("message")}
                />
              </div>

              <button
                type="submit"
                className="submit-button"
                disabled={loading}
              >
                {loading ? (
                  <>
                    <Spinner animation="border" size="sm" /> Submitting...
                  </>
                ) : (
                  "Send Message"
                )}
              </button>
            </form>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Contact;
