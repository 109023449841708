import React, { useEffect, useState } from "react";
import "../Timeline/Timeline.css";
import { Form, Modal, Spinner } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import SignatureCanvasComponent from "../../Components/SignatureCanvas/SignatureCanvas";
import { useParams } from "react-router-dom";

const TimelineForm = () => {
  const { c_id } = useParams();
  const userid = localStorage.getItem("brooks_user_id");

  const [step, setStep] = useState(1);
  const [leaseType, setLeaseType] = useState(""); // State for lease type
  const [phone, setPhone] = useState("");
  const [phoneError, setPhoneError] = useState(false);
  const [loadersub, setloadersub] = useState(false);
  // const [leaseType, setLeaseType] = useState(""); // State for lease type

  const [formData, setFormData] = useState({
    name: "",
    address: "",
    mobile: "",
    email: "",
    contact_person: "",
    service: leaseType,
    numberOfContainers: "",
    containerSpecifications: "",
    condition: "",
    leaseDuration: "",
    rentalRate: "",
    payment_frequency: "",
    purchasePrice: "", // New field for purchase price
    rentalPrice: "", // New field for rental price
    containersize: "",
    leaseStartDate: "",
    leaseEndDate: "",
    payment_method: "",
  });

  const [errors, setErrors] = useState({
    name: false,
    address: false,
    mobile: false,
    email: false,
  });

  const [isLiabilityChecked, setIsLiabilityChecked] = useState(false); // Track checkbox state

  // Function to handle checkbox change
  const handleCheckboxChange = (e) => {
    setIsLiabilityChecked(e.target.checked);
  };

  const [isLiabilityCheckedres, setIsLiabilityCheckedres] = useState(false); // Track checkbox state

  // Function to handle checkbox change
  const handleCheckboxChangeres = (e) => {
    setIsLiabilityCheckedres(e.target.checked);
  };
  const [validationErrors, setValidationErrors] = useState({});

  const validateContainerInformation = () => {
    const errors = {};

    if (!leaseType) errors.leaseType = "Please select a service type.";
    if (!formData.numberOfContainers)
      errors.numberOfContainers = "Please select the number of containers.";
    if (!formData.containersize)
      errors.containersize = "Please select a container size.";
    if (!formData.containerSpecifications)
      errors.containerSpecifications =
        "Please select a container Specification";
    if (!formData.condition)
      errors.condition = "Please select a container condition.";
    if (!formData.payment_method)
      errors.payment_method = "Please select a payment method.";

    if (
      leaseType === "Rent" &&
      (!formData.leaseStartDate || !formData.leaseEndDate)
    ) {
      errors.leaseDates = "Please select both start and end dates for rent.";
    } else if (
      leaseType === "Rent" &&
      formData.leaseStartDate &&
      formData.leaseEndDate &&
      new Date(formData.leaseEndDate) < new Date(formData.leaseStartDate)
    ) {
      errors.leaseDates = "End date cannot be earlier than start date.";
    }
    if (formformview.cs_brand_new_price === undefined) {
      errors.cs_brand_new_price = "Please try Another Variant";
    }

    if (
      leaseType === "Buy/Rent" &&
      (!formData.leaseStartDate || !formData.leaseEndDate)
    ) {
      errors.leaseDates = "Please select both start and end dates for rent.";
    } else if (
      leaseType === "Buy/Rent" &&
      formData.leaseStartDate &&
      formData.leaseEndDate &&
      new Date(formData.leaseEndDate) < new Date(formData.leaseStartDate)
    ) {
      errors.leaseDates = "End date cannot be earlier than start date.";
    }

    if (leaseType === "Rent" && !formData.rentalRate) {
      errors.rentalRate = "Please select  a rental Payment Mode";
    }

    if (leaseType === "Buy/Rent" && !formData.payment_frequency) {
      errors.payment_frequency = "Please select Payment Mode.";
    }

    setValidationErrors(errors);

    console.log(validationErrors);

    return Object.keys(errors).length === 0; // Returns true if there are no errors
  };

  const validateClientInformation = () => {
    const newErrors = {};

    // Name validation
    if (!formData.name) {
      newErrors.name = "Name is required";
    } else if (!/^[A-Za-z\s]+$/.test(formData.name)) {
      newErrors.name = "Name must contain only letters and spaces";
    } else if (formData.name.length < 3) {
      newErrors.name = "Name must be at least 3 characters long";
    } else if (formData.name.length > 300) {
      newErrors.name = "Name must be no more than 300 characters long";
    }

    // Address validation
    if (!formData.address || formData.address.trim().length < 4) {
      newErrors.address = "Address must be at least 4 characters long";
    } else if (formData.address.length > 300) {
      newErrors.address = "Address must be no more than 300 characters long";
    }

    // Email validation
    if (!formData.email) {
      newErrors.email = "Email is required";
    } else if (!/^[^\s@]+@[^\s@]+\.(com|in|net|org)$/i.test(formData.email)) {
      newErrors.email =
        "Please enter a valid email address ending in .com, .in, .net, or .org";
    }

    if (!phone) {
      newErrors.mobile = "Mobile number is required"; // Error if field is empty
    } else if (phone.trim().length < 8) {
      newErrors.mobile = "Mobile number must be at least 8 digits"; // Error if less than 10 digits
    }

    setErrors(newErrors);
    console.log(newErrors);

    return Object.keys(newErrors).length === 0; // Returns true if no errors
  };

  console.log(leaseType, "loggg");

  const [sizeConatiner, setsizeConatiner] = useState([]);
  const [namecontainername, setnamecontainername] = useState("");

  const [sizeConatinerlast, setsizeConatinerlast] = useState([]);

  useEffect(() => {
    const fetchProductData = async () => {
      try {
        const response = await axios.post(
          "https://lunarsenterprises.com:4002/brookscontainer/container/view",
          { c_id: c_id }
        );

        const productData = response.data.data[0].c_name;
        const productsize = response.data.data[0].size;

        const productsizemain = response.data.data[0].size;

        setsizeConatinerlast(productsizemain);
        setsizeConatiner(productsize || []); // Set as an array, default to empty array
        setnamecontainername(productData);
        console.log("Product timeline:", productData);
      } catch (error) {
        console.error("Error fetching product data:", error);
      }
    };

    fetchProductData();
  }, []);

  // Handle form input changes
  const handleChange = (e) => {
    // fetchformview()
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const nextStep = () => {
    if (step === 1 && validateClientInformation()) {
      setStep(step + 1);
    } else if (step === 2 && validateContainerInformation()) {
      setStep(step + 1);
    } else if (step === 3 && validateResponsibilities()) {
      setStep(step + 1);
    } else if (step === 4 && validateLiability()) {
      setStep(step + 1);
    } else if (step === 5 && validateTerminationAndRenewal()) {
      setStep(step + 1);
    }
  };

  // Validation for Responsibilities (Step 3)
  const validateResponsibilities = () => {
    const errors = {};
    if (!isLiabilityCheckedres) {
      errors.liabilityres = "You must agree to the Responsibilities";
    }
    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  // Validation for Liability Clause (Step 4)
  const validateLiability = () => {
    const errors = {};
    if (!isLiabilityChecked) {
      errors.liability = "You must agree to the liability clause to proceed.";
    }
    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const [isLiabilityCheckedter, setIsLiabilityCheckedrester] = useState(false); // Track checkbox state

  // Function to handle checkbox change
  const handleCheckboxChangeter = (e) => {
    setIsLiabilityCheckedrester(e.target.checked);
  };
  // Validation for Termination and Renewal (Step 5)
  const validateTerminationAndRenewal = () => {
    const errors = {};
    // Add any specific validation needed for Step 5 here
    if (!isLiabilityCheckedter) {
      errors.liabilityter = "You must agree to the  Termination and Renewal";
    }

    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  // Move to previous step
  const prevStep = () => {
    setStep(step - 1);
  };

  // Awareness sections for each step
  const awarenessText = [
    "In this step, please provide your personal or company information that will be used for the leasing agreement.",
    "Provide details about the container(s) you wish to Buy/Rent, including specifications and condition (new or used).",
    "Select the leasing terms, including Buy/Rent type, duration, rental rate, and payment frequency.",
    "Brooks Containers will manage leasing, ensure payments, and provide maintenance services as outlined.",
    "You are responsible for ensuring the container is in good condition and providing necessary ownership documentation.",
    "You can terminate the Buy/Rent with a 30/60-day notice or renew it based on mutual agreement.",
  ];

  // Render different step content

  const [newformformview2, setNewFormformview2] = useState([]);
  const [Conatinerformview, setConatinerformview] = useState([]);
  const [formformview, setFormformview] = useState({});

  const [formorder, setformorder] = useState();

  useEffect(() => {
    const fetchformview = async () => {
      if (
        formData.containersize ||
        formData.containerSpecifications ||
        formData.condition
      )
        // return; // Only proceed if containersize is selected

        try {
          const response = await axios.post(
            "https://lunarsenterprises.com:4002/brookscontainer/container/view/form",
            {
              c_id: c_id,
              size: formData.containersize, // Pass the selected container size
              body_type: formData.containerSpecifications, // Add any additional fields as needed
              body_condition: formData.condition,
            }
          );

          const productdataformview = response.data.data[0].size;
          const productsize = response.data.data[0].size;

          setNewFormformview2(productsize);
          setConatinerformview(productdataformview || []);
          console.log(Conatinerformview, "productdataformview");

          // Update the state with the response data
          setFormformview({
            cs_brand_new_price: productdataformview[0].cs_brand_new_price,
            cs_parking_charge: productdataformview[0].cs_parking_charge,
            cs_service_fee: productdataformview[0].cs_service_fee,
            cs_total_cost: productdataformview[0].cs_total_cost,
            cs_rental_cost: productdataformview[0].cs_rental_cost,
          });

          console.log("Fetched form data:", productdataformview);
        } catch (error) {
          console.error("Error fetching form data:", error);
        }
    };

    fetchformview();
  }, [
    formData.containersize,
    formData.containerSpecifications,
    formData.condition,
  ]);

  const handleContainerSizeChange = (e) => {
    const selectedSize = e.target.value;
    const selectedContainer = Conatinerformview.find(
      (container) => container.cs_size === parseInt(selectedSize)
    );
    if (selectedContainer) {
      setFormData({
        ...formData,
        containersize: selectedSize,
        bodyType: selectedContainer.cs_body_type,
        brandNewPrice: selectedContainer.cs_brand_new_price,
        parkingCharge: selectedContainer.cs_parking_charge,
        serviceFee: selectedContainer.cs_service_fee,
        totalCost: selectedContainer.cs_total_cost,
        rentalCost: selectedContainer.cs_rental_cost,
      });
    }
  };

  const addorder = async () => {
    setloadersub(true);
    try {
      const response = await axios.post(
        "https://lunarsenterprises.com:4002/brookscontainer/add-order",
        {
          container_name: namecontainername,
          name: formData.name,
          address: formData.address,
          mobile: phone,
          email: formData.email,
          contact_person: formData.contact_person,
          service: leaseType,
          start_date: formData.leaseStartDate,
          end_date: formData.leaseEndDate,
          payment_frequency: formData.payment_frequency,
          rental_contract: formData.rentalRate,
          payment_method: formData.payment_method,
          container: [
            {
              body_type: formData.containerSpecifications,
              size: formData.containersize,
              quantity: formData.numberOfContainers,
              amount: totalCost,
              condition: formData.condition,
              rental_amount: rental,
            },
          ],
        },
        {
          headers: {
            u_id: userid,
          },
        }
      );

      console.log();

      console.log(response);

      if (response.data.result) {
        setPdfLink(response.data.pdf); // Set PDF link from response
        setShowPdfModal(true); // Show the PDF modal
        localStorage.setItem("order_id", response.data.order_id);
      }
    } catch (error) {
    } finally {
      setloadersub(false);
    }
  };

  const [showPdfModal, setShowPdfModal] = useState(false);
  const [pdfLink, setPdfLink] = useState("");
  const handleClosePdfModal = () => setShowPdfModal(false);

  const [totalCost, setTotalCost] = useState(0);
  const [brandprice, setBrandprice] = useState(0);
  const [park, setPark] = useState(0);
  const [rental, setRental] = useState(0);
  const [servicefee, setServicefee] = useState(0);
  // // Function to calculate and set the total cost
  // useEffect(() => {
  //   const calculatedTotal =
  //     (formformview.cs_total_cost || 0) * (formData.numberOfContainers || 1

  //     );
  //   setTotalCost(calculatedTotal); // Update the totalCost state with calculated value
  // }, [formformview.cs_total_cost, formData.numberOfContainers]); // Recalculate when dependencies change

  useEffect(() => {
    const numContainers = formData.numberOfContainers || 1;

    // Calculate years difference between start and end dates
    const startDate = new Date(formData.leaseStartDate);
    const endDate = new Date(formData.leaseEndDate);

    // Calculate the difference in months and divide by 12 to get approximate years
    const yearDiff =
      endDate > startDate
        ? Math.max(1, (endDate - startDate) / (1000 * 60 * 60 * 24 * 365.25))
        : 1;

    // Calculate each cost value based on the number of containers and years (or months if <1 year)
    const calculatedTotalCost =
      (formformview.cs_total_cost || 0) * numContainers * yearDiff;
    const calculatedBrandPrice =
      (formformview.cs_brand_new_price || 0) * numContainers;
    const calculatedParking =
      (formformview.cs_parking_charge || 0) * numContainers * yearDiff;
    const calculatedRental = (formformview.cs_rental_cost || 0) * numContainers;
    const calculatedServiceFee =
      (formformview.cs_service_fee || 0) * numContainers * yearDiff;

    // Update states with calculated values
    setTotalCost(calculatedTotalCost);
    setBrandprice(calculatedBrandPrice);
    setPark(calculatedParking);
    setRental(calculatedRental);
    setServicefee(calculatedServiceFee);
  }, [
    formformview.cs_total_cost,
    formformview.cs_brand_new_price,
    formformview.cs_parking_charge,
    formformview.cs_rental_cost,
    formformview.cs_service_fee,
    formData.numberOfContainers,
    formData.leaseStartDate,
    formData.leaseEndDate,
  ]);

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <div>
            {step === 1 && (
              <div className="form-step">
                <h2>Client Information</h2>

                {/* Name Field */}
                <Form.Label className="mt-2">
                  Full Name / Company Name
                </Form.Label>
                <input
                  type="text"
                  name="name"
                  placeholder="Full Name/Company Name"
                  value={formData.name}
                  onChange={handleChange}
                  className={errors.name ? "error" : ""}
                />
                {errors.name && <p className="error-message">{errors.name}</p>}

                {/* Address Field */}
                <Form.Label>Address</Form.Label>
                <input
                  type="text"
                  name="address"
                  placeholder="Address"
                  value={formData.address}
                  onChange={handleChange}
                  className={errors.address ? "error" : ""}
                />
                {errors.address && (
                  <p className="error-message">{errors.address}</p>
                )}

                <Form.Label>Contact Number</Form.Label>
                <PhoneInput
                  country={"us"}
                  value={phone}
                  onChange={(value) => {
                    setPhone(value);
                  }}
                  inputStyle={{ width: "100%" }}
                />

                <p className="error-message">{errors.mobile}</p>

                {/* Email Field */}
                <Form.Label className="mt-2">Email</Form.Label>
                <input
                  type="email"
                  name="email"
                  placeholder="Email Address"
                  value={formData.email}
                  onChange={handleChange}
                  className={errors.email ? "error" : ""}
                />
                {errors.email && (
                  <p className="error-message">{errors.email}</p>
                )}

                {/* Contact Person Field */}
                <Form.Label>Contact Person (if company)</Form.Label>
                <input
                  type="text"
                  name="contact_person"
                  placeholder="Contact Person (if company)"
                  value={formData.contact_person}
                  onChange={handleChange}
                />
              </div>
            )}
          </div>
        );
      case 2:
        return (
          <div>
            {step === 2 && (
              <div className="form-step">
                <h2>Container Information</h2>
                <Form.Label className="">Select Service</Form.Label>

                <select
                  name="leaseType"
                  onChange={(e) => {
                    setLeaseType(e.target.value); // Update lease type state
                    handleChange(e); // Update formData
                  }}
                  value={leaseType}
                >
                  <option value="">Select Buy & Rent Or Rent</option>

                  <option value="Buy/Rent">Buy/Rent</option>
                  <option value="Rent">Rent</option>
                </select>
                {validationErrors.leaseType && (
                  <p className="error-message">{validationErrors.leaseType}</p>
                )}
                <Form.Group controlId="condition">
                  <Form.Label>Select Container Condition</Form.Label>
                  <div>
                    <Form.Control
                      as="select"
                      name="condition"
                      value={formData.condition}
                      onChange={(e) => {
                        handleChange(e); // Update formData for condition
                        // Reset containersize and containerSpecifications to default values
                        setFormData((prevData) => ({
                          ...prevData,
                          containersize: "", // Reset containersize to default
                          containerSpecifications: "", // Reset containerSpecifications to default
                        }));
                      }}
                      style={{ backgroundColor: "white" }}
                    >
                      <option value="">Select New/Used</option>
                      <option value="new">New</option>
                      <option value="used">Used</option>
                    </Form.Control>
                  </div>
                </Form.Group>
                {validationErrors.condition && (
                  <p className="error-message">{validationErrors.condition}</p>
                )}

                <Form.Group controlId="containersize">
                  <Form.Label>Select Container Size</Form.Label>
                  <div>
                    <Form.Control
                      as="select"
                      name="containersize"
                      value={formData.containersize}
                      placeholder=""
                      onChange={(e) => {
                        handleChange(e); // Update formData for condition
                        // Reset containersize and containerSpecifications to default values
                        setFormData((prevData) => ({
                          ...prevData,
                          // Reset containersize to default
                          containerSpecifications: "", // Reset containerSpecifications to default
                        }));
                      }}
                    >
                      <option value="">Container Size</option>
                      {newformformview2?.length > 0 ? (
                        newformformview2.map((num) => (
                          <option
                            style={{ maxHeight: "50px", overflowY: "auto" }}
                            key={num.cs_size}
                            value={num.cs_size}
                          >
                            {num.cs_size} ft
                          </option>
                        ))
                      ) : (
                        <option value="">No Size Available</option>
                      )}
                    </Form.Control>
                  </div>
                </Form.Group>
                {validationErrors.containersize && (
                  <p className="error-message">
                    {validationErrors.containersize}
                  </p>
                )}

                <Form.Group controlId="containerSpecifications">
                  <Form.Label>Select Specification</Form.Label>
                  <div>
                    <Form.Control
                      as="select"
                      name="containerSpecifications"
                      value={formData.containerSpecifications}
                      onChange={handleChange}
                      style={{
                        maxHeight: "100px",
                        overflowY: "auto",
                        backgroundColor: "white",
                      }} // Scrollable dropdown
                    >
                      <option value="">Container Specification</option>
                      {newformformview2?.length > 0 ? (
                        newformformview2.map((num) => (
                          <option
                            style={{ maxHeight: "50px", overflowY: "auto" }}
                            key={num.cs_body_type}
                            value={num.cs_body_type}
                          >
                            {num.cs_body_type}
                          </option>
                        ))
                      ) : (
                        <option value="">No Specification Available</option>
                      )}
                    </Form.Control>
                  </div>
                  {validationErrors.containerSpecifications && (
                    <p className="error-message">
                      {validationErrors.containerSpecifications}
                    </p>
                  )}
                </Form.Group>

                <Form.Group controlId="numberOfContainers">
                  <Form.Label className="">
                    Select Quantity of Container
                  </Form.Label>

                  <div>
                    <Form.Control
                      as="select"
                      name="numberOfContainers"
                      value={formData.numberOfContainers}
                      onChange={handleChange}
                      style={{
                        maxHeight: "100px",
                        overflowY: "auto",
                        backgroundColor: "white",
                      }} // Scrollable dropdown
                    >
                      <option value="">Select Number of Containers</option>

                      {/* Check if stock is available */}
                      {newformformview2.length > 0 &&
                      newformformview2[0].cs_stock > 0 ? (
                        // Render options up to the cs_size
                        Array.from(
                          { length: newformformview2[0].cs_stock },
                          (_, i) => (
                            <option key={`container-${i + 1}`} value={i + 1}>
                              {i + 1}
                            </option>
                          )
                        )
                      ) : (
                        // Show "No stock available" if size is zero
                        <option value="">No stock available</option>
                      )}
                    </Form.Control>
                  </div>

                  <div></div>
                </Form.Group>
                {validationErrors.numberOfContainers && (
                  <p className="error-message">
                    {validationErrors.numberOfContainers}
                  </p>
                )}

                {leaseType === "Buy/Rent" && (
                  <>
                    <div className="row">
                      <div className="col-md-6">
                        <Form.Label>Start Date</Form.Label>
                        <input
                          type="date"
                          name="leaseStartDate"
                          value={formData.leaseStartDate}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="col-md-6">
                        <Form.Label>End Date</Form.Label>
                        <input
                          type="date"
                          name="leaseEndDate"
                          value={formData.leaseEndDate}
                          onChange={handleChange}
                        />
                      </div>

                      {validationErrors.leaseDates && (
                        <p className="error-message">
                          {validationErrors.leaseDates}
                        </p>
                      )}
                    </div>
                  </>
                )}

                <Form.Label className=""> Brand Price</Form.Label>

                <Form.Control
                  type="text"
                  name="containersize"
                  value={`$${brandprice}`}
                  placeholder="Price"
                  style={{ backgroundColor: "white" }}
                  readOnly
                ></Form.Control>

                {validationErrors.cs_brand_new_price && (
                  <p className="error-message">
                    {validationErrors.cs_brand_new_price}
                  </p>
                )}

                <Form.Label className="">Parking Charge (10% /Year)</Form.Label>

                <Form.Control
                  type="text"
                  name="containersize"
                  value={`$${park}`}
                  placeholder="Parking Charge"
                  style={{ backgroundColor: "white" }}
                  readOnly
                ></Form.Control>

                <Form.Label className="">Service/Year</Form.Label>

                <Form.Control
                  type="text"
                  name="containersize"
                  value={`$${servicefee}`}
                  placeholder="Service Fee"
                  style={{ backgroundColor: "white" }}
                  readOnly
                />

                {/* <Form.Label className="">Rental Cost/Year</Form.Label>

                <Form.Control
                  type="text"
                  name="containersize"
                  value={`$${rental}`}
                  placeholder="Service Fee"
                  style={{ backgroundColor: "white" }}
                  readOnly
                /> */}


{/* <div>
  <Form.Label className="">Select Income Mode</Form.Label>
  <select
    name="payment_frequency"
    onChange={handleChange}
    value={formData.payment_frequency}
  >
    <option value="">Select Income Mode</option>
    <option value="Fixed Passive Income">Fixed Passive Income</option>
    <option value="Flexible Passive Income">Flexible Passive Income</option>
  </select>
  {validationErrors.payment_frequency && (
    <p className="error-message">{validationErrors.payment_frequency}</p>
  )}
</div> */}

{/* Conditionally render Rental Cost/Year field */}
{formData.payment_frequency !== "Flexible Passive Income" && (
  <div>
    <Form.Label className="">Rental Cost/Year</Form.Label>
    <Form.Control
      type="text"
      name="containersize"
      value={`$${rental}`}
      placeholder="Service Fee"
      style={{ backgroundColor: "white" }}
      readOnly
    />
  </div>
)}


                <Form.Label className="">Total Cost </Form.Label>

                {/* <Form.Control
              type="text"
              value={`$${
                (formformview.cs_total_cost || 0) *
                (formData.numberOfContainers || 1)
              }`}
              placeholder="Total Cost"
              readOnly
              style={{ backgroundColor: "white" }}
            /> */}

                <Form.Control
                  type="text"
                  name="totalCostDisplay"
                  value={`$${totalCost}`}
                  readOnly
                  style={{ backgroundColor: "white" }}
                />

                {leaseType === "Buy/Rent" && (
                  <div>
                    <Form.Label className="">Select Income Mode </Form.Label>
                    <select
                      name="payment_frequency"
                      onChange={handleChange}
                      value={formData.payment_frequency}
                    >
                      <option value="">Select Income Mode</option>

                      <option value="Fixed Passive Income">
                        Fixed Passive Income
                      </option>
                      <option value="Flexible Passive Income">
                        Flexible Passive Income
                      </option>
                    </select>
                    {validationErrors.payment_frequency && (
                      <p className="error-message">
                        {validationErrors.payment_frequency}
                      </p>
                    )}
                  </div>
                )}

                {leaseType === "Rent" && (
                  <>
                    <div className="row">
                      <div className="col-md-6">
                        <Form.Label>Start Date</Form.Label>
                        <input
                          type="date"
                          name="leaseStartDate"
                          value={formData.leaseStartDate}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="col-md-6">
                        <Form.Label>End Date</Form.Label>
                        <input
                          type="date"
                          name="leaseEndDate"
                          value={formData.leaseEndDate}
                          onChange={handleChange}
                        />
                      </div>

                      {validationErrors.leaseDates && (
                        <p className="error-message">
                          {validationErrors.leaseDates}
                        </p>
                      )}
                    </div>
                    <select
                      name="rentalRate"
                      onChange={handleChange}
                      value={formData.rentalRate}
                    >
                      <option value="">Select Payment Frequency</option>
                      <option value="Monthly">Monthly</option>
                      <option value="Quarterly">Quarterly</option>
                      <option value="Annually">Annually</option>
                    </select>

                    {validationErrors.rentalRate && (
                      <p className="error-message">
                        {validationErrors.rentalRate}
                      </p>
                    )}
                  </>
                )}
                <Form.Label className="">Payment Mode </Form.Label>

                <select
                  name="payment_method"
                  onChange={handleChange}
                  value={formData.payment_method}
                >
                  <option value="">Payment Method Mode</option>
                  <option value="Banktransfer">Bank Transfer</option>
                  <option value="Cheque">Cheque</option>
                  <option value="Credit">Credit/Debit Card</option>
                  <option value="Cash">Cash</option>
                </select>
                {validationErrors.payment_method && (
                  <p className="error-message">
                    {validationErrors.payment_method}
                  </p>
                )}
              </div>
            )}
          </div>
        );

      case 3:
        return (
          <div>
            {step === 3 && (
              <div className="form-step">
                <h2>Responsibilities</h2>
                <p>
                  Ensure containers are well-maintained and provide necessary
                  documentation before leasing.
                </p>
                <Form.Group
                  controlId="liabilityCheckbox"
                  className="d-flex align-items-center"
                >
                  <Form.Check
                    type="checkbox"
                    checked={isLiabilityCheckedres}
                    onChange={handleCheckboxChangeres}
                    className="me-2"
                    isInvalid={!!validationErrors.liabilityres} // Ensure validationErrors instead of errors
                  />
                  <Form.Label>I agree to the Responsibilities</Form.Label>
                </Form.Group>
                {validationErrors.liabilityres && (
                  <div className="invalid-feedback d-block">
                    {validationErrors.liabilityres}
                  </div>
                )}
              </div>
            )}
          </div>
        );
      case 4:
        return (
          <div>
            {step === 4 && (
              <div className="form-step">
                <h2>Liability Clause</h2>
                <p>
                  The client assumes responsibility for ensuring containers are
                  insured against damages or losses during leasing.
                </p>
                <Form.Group
                  controlId="liabilityCheckbox"
                  className="d-flex align-items-center"
                >
                  <Form.Check
                    type="checkbox"
                    checked={isLiabilityChecked}
                    onChange={handleCheckboxChange}
                    className="me-2" // Adds spacing between checkbox and label
                  />
                  <Form.Label>I agree to the liability clause</Form.Label>
                </Form.Group>
                {validationErrors.liability && (
                  <div className="invalid-feedback d-block">
                    {validationErrors.liability}
                  </div>
                )}
              </div>
            )}
          </div>
        );
      case 5:
        return (
          <div>
            {step === 5 && (
              <div className="form-step">
                <h2>Termination and Renewal</h2>
                <p>
                  Both parties can terminate the agreement with a 30/60-day
                  notice. The agreement can be renewed based on mutual
                  agreement.
                </p>

                <Form.Group
                  controlId="liabilityCheckbox"
                  className="d-flex align-items-center"
                >
                  <Form.Check
                    type="checkbox"
                    checked={isLiabilityCheckedter}
                    onChange={handleCheckboxChangeter}
                    className="me-2" // Adds spacing between checkbox and label
                  />
                  <Form.Label>
                    I agree to the Termination and Renewal
                  </Form.Label>
                </Form.Group>
                {validationErrors.liabilityter && (
                  <div className="invalid-feedback d-block">
                    {validationErrors.liabilityter}
                  </div>
                )}
              </div>
            )}
          </div>
        );
      default:
        return (
          <div>
            <h2>Thank you! You have successfully completed the form.</h2>
          </div>
        );
    }
  };

  const steps = [
    "Client Information",
    "Container Information",
    "Buy/Rent Terms",
    "Responsibilities",
    "Liability Clause",
    "Termination and Renewal",
  ];

  const [signature, setSignature] = useState("");

  const handleSaveSignature = (dataURL) => {
    setSignature(dataURL);
    // You can also handle further actions here (e.g., sending to the server)
  };
  return (
    <>
      <Modal
        show={showPdfModal}
        dialogClassName="modal-xl"
        onHide={handleClosePdfModal}
        className=" modalsign overflow-auto"
      >
        <Modal.Header closeButton>
          <Modal.Title>Order Agreement</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <iframe
            src={pdfLink}
            title="PDF Document"
            width="100%"
            height="600px"
          />

          <h5 className="mt-3">Signature:</h5>
          <SignatureCanvasComponent onSave={handleSaveSignature} />
          {signature && (
            <div>
              <h6>Preview:</h6>
              <img
                src={signature}
                alt="Signature"
                style={{ width: "100%", height: "auto" }}
              />
            </div>
          )}
        </Modal.Body>
      </Modal>

      <ul className="">
        <h2>Timeline Steps:</h2>
        {steps.map((step, index) => (
          <li key={index}>
            <div className="d-flex">
              <div className="">✔️ </div>
              <strong>{step}</strong>
            </div>
          </li>
        ))}
      </ul>

      <div className="timeline-form">
        <div className="timeline">
          {steps.map((_, i) => (
            <div key={i} className={`step ${step >= i + 1 ? "active" : ""}`}>
              Step {i + 1}
            </div>
          ))}
        </div>

        <div className="awareness-section">
          <p>{awarenessText[step - 1]}</p>
        </div>

        {renderStep()}

        <div className="form-navigation">
          {step > 1 && <button onClick={prevStep}>Back</button>}
          {step < steps.length && (
            <button
              disabled={step === 1 && !validateClientInformation}
              onClick={nextStep}
            >
              Next
            </button>
          )}
          {step === steps.length && (
            <button disabled={loadersub} onClick={addorder}>
              {loadersub ? (
                <>
                  <Spinner animation="grow" size="sm" /> Submitting...
                </>
              ) : (
                <>
                  Submit
                  <FontAwesomeIcon
                    icon={faArrowRight}
                    className="ms-2 text-white"
                  />
                </>
              )}
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export default TimelineForm;





// import React, { useState } from "react";
// import { Form } from "react-bootstrap";

// function IncomeForm() {
//   const [formData, setFormData] = useState({
//     payment_frequency: "",
//   });
//   const [validationErrors, setValidationErrors] = useState({});
//   const [showRental, setShowRental] = useState(false); // New state to control rental visibility
//   const [rental, setRental] = useState(100); // Example rental cost (adjust as needed)

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData((prevData) => ({ ...prevData, [name]: value }));

//     if (name === "payment_frequency") {
//       // Show rental field if "Flexible Passive Income" is selected
//       setShowRental(value === "Flexible Passive Income");
//     }
//   };

//   return (
//     <div>
//       <Form.Label>Select Income Mode</Form.Label>
//       <select
//         name="payment_frequency"
//         onChange={handleChange}
//         value={formData.payment_frequency}
//       >
//         <option value="">Select Income Mode</option>
//         <option value="Fixed Passive Income">Fixed Passive Income</option>
//         <option value="Flexible Passive Income">Flexible Passive Income</option>
//       </select>
//       {validationErrors.payment_frequency && (
//         <p className="error-message">
//           {validationErrors.payment_frequency}
//         </p>
//       )}

//       {showRental && (
//         <div>
//           <Form.Label>Rental Cost (70:30 ratio)</Form.Label>
//           <Form.Control
//             type="text"
//             name="containersize"
//             value={`$${(rental * 0.7).toFixed(2)}`} // Calculate 70% of rental
//             placeholder="Service Fee"
//             style={{ backgroundColor: "white" }}
//             readOnly
//           />
//         </div>
//       )}
//     </div>
//   );
// }

// export default IncomeForm;
