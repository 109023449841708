import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { motion } from "framer-motion"; // Import framer-motion
import PropTypes from "prop-types";
import '../About/About.scss';
import dummyimg from '../images/transport.jpg';
import loginsign from '../images/loginsign.jpg';


const saleRental = {
  title: "Rental Services",
  description: `
    We offer a wide range of products available for both Buy and Rent and rental, tailored to meet your specific needs. Whether you're looking for a long-term investment or short-term rental options, we've got you covered with high-quality, comfortable, and durable products. Experience convenience and flexibility with our seamless rental process and exclusive purchase options.
  `,
  image: dummyimg, 
};

const missionVision = {
  title: "Our Mission and Vision",
  mission: `

Brooks Containers FZCO is dedicated to offering cutting-edge container rental services that provide dependable income streams for container owners. Our goal is to connect supply with demand by delivering versatile and efficient container options, nurturing long-lasting relationships built on trust, openness, and a commitment to sustainability.
  `,
  vision: `

To become the foremost provider of eco-friendly and adaptable container leasing solutions, enabling container owners to earn passive income while contributing to the smooth movement of global commerce.

  `,
  image: loginsign, 
};

// Slower motion variants with increased duration and delay
const textVariantLeft = {
  hidden: { opacity: 0, x: -100 },
  visible: { 
    opacity: 1, 
    x: 0, 
    transition: { duration: 2, delay: 0.5 } // Increased duration to 2s
  }
};

const textVariantRight = {
  hidden: { opacity: 0, x: 100 },
  visible: { 
    opacity: 1, 
    x: 0, 
    transition: { duration: 2, delay: 0.5 } // Increased duration to 2s
  }
};

const imageVariant = {
  hidden: { opacity: 0, scale: 0.8 },
  visible: { 
    opacity: 1, 
    scale: 1, 
    transition: { duration: 1.5, delay: 0.8 } // Increased duration to 2s for images
  }
};

const StoryItem = ({ item, index }) => {
  const { title, description, image } = item;

  return (
    <>
      <Col
        xs={12}
        md={5}
        className={index % 2 === 0 ? "order-2" : "order-2 order-md-1"}
      >
        {/* Add motion.div to text */}
        <motion.div
          className={`d-flex flex-column justify-content-center ezy__about6-content ${
            index % 2 === 0 ? "ps-md-5" : "pe-md-5"
          }`}
          initial="hidden"
          whileInView="visible"
          variants={index % 2 === 0 ? textVariantLeft : textVariantRight}
        >
          <h2 className="fw-bold mb-3">{title}</h2>
          <p className="mb-0">{description}</p>
        </motion.div>
      </Col>
      <Col
        xs={12}
        md={5}
        className={`${
          index % 2 === 0 ? "order-1" : "order-1 order-md-2"
        } mb-4 mb-md-0`}
      >
        {/* Add motion.div to image */}
        <motion.div
          initial="hidden"
          whileInView="visible"
          variants={imageVariant}
        >
          <img
            src={image}
            alt={title}
            className={`img-fluid story-item-image-${index}`}
          />
        </motion.div>
      </Col>
    </>
  );
};

StoryItem.propTypes = {
  item: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
};

const About = () => {
  return (
    <section className="ezy__about6 light " id="ezy__about6">
      <Container>

        {/* Sale Rental Section */}
        <Row className="align-items-center justify-content-center ">
          <StoryItem item={saleRental} index={1} />
        </Row>

        {/* Mission & Vision Section */}
        <Row className="align-items-center justify-content-center mt-5">
          <Col xs={12} md={5} className="order-2">
            <motion.div
              className="d-flex flex-column justify-content-center ezy__about6-content ps-md-5"
              initial="hidden"
              whileInView="visible"
              variants={textVariantLeft}
            >
              <h5 className="fw-bold">Mission</h5>
              <p>{missionVision.mission}</p>
              <h5 className="fw-bold">Vision</h5>
              <p>{missionVision.vision}</p>
            </motion.div>
          </Col>
          <Col xs={12} md={5} className="order-1 mb-4 mb-md-0">
            <motion.div
              initial="hidden"
              whileInView="visible"
              variants={imageVariant}
            >
              <img src={missionVision.image} alt={missionVision.title} className="img-fluid" />
            </motion.div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default About;
