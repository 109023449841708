import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Navbar, Nav, NavDropdown, Image } from "react-bootstrap";
import "../../Components/Header/Header.scss"; // Import your custom CSS
import Logo from "../../Pages/images/logo.png";
import axios from "axios";

const Header = () => {
  const location = useLocation(); // Get current route path
  const [scrolled, setScrolled] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [expanded, setExpanded] = useState(false); // State to control the navbar toggle

  const handleScroll = () => {
    setScrolled(window.scrollY > 50);
  };

  const handleMouseEnter = () => {
    setShowDropdown(true);
  };

  const handleMouseLeave = () => {
    setShowDropdown(false);
  };

  const handleDropdownClick = () => {
    setShowDropdown(false); // Close dropdown after item is clicked
    setExpanded(false); // Close navbar after item is clicked
  };

  const handleToggle = () => {
    setExpanded(!expanded); // Toggle the expanded state
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const userid = localStorage.getItem("brooks_user_id");


  const navigate = useNavigate();
  const handleProfileNavigation = () => {
    const brooksToken = localStorage.getItem('brooks_token');
    const brooksUserId = localStorage.getItem('brooks_user_id');

    if (brooksToken && brooksUserId) {
      navigate('/Profile');

      setExpanded(false);
      // navigate('/Profile');
    } else {
      alert('You must be logged in to access the profile.');
      navigate('/');
    }
  };

useEffect(() => {
  Profileimg()
 
}, []);
const [Profile, setProfile] = useState(null);


  const Profileimg = async () => {
    try {
      const response = await axios.post(
        "https://lunarsenterprises.com:4002/brookscontainer/profile/view",
        {},
        {
          headers: {
            u_id: userid,
          },
        }
      );
      setProfile(response.data.data[0]); // setProfile to a single object
    } catch (error) {
      console.error("Error fetching profile data:", error);
    }
  };

  return (
    <Navbar
      expand="lg"
      className={`header-navbar ${scrolled ? "scrolled" : "white"}`}
      fixed="top"
      expanded={expanded} // Control navbar toggle state
    >
      <div className="container">
        <Navbar.Brand as={Link} to="/" className="logo">
          <Image src={Logo} alt="image" className="imagestyle" />
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="basic-navbar-nav"
          onClick={handleToggle} // Toggle navbar on click
        />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto ">
           <Nav.Link
              as={Link}
              to="/"
              className={`nav-link boldname hovereffect ${
                location.pathname === "/" ? "active" : ""
              }`}
              onClick={() => setExpanded(false)}
            >
              Home
            </Nav.Link>



            <Nav.Link
              as={Link}
              to="/how-to-work"
              className={`nav-link boldname hovereffect ${
                location.pathname === "/how-to-work" ? "active" : ""
              }`}
              onClick={() => setExpanded(false)}
            >
          User Manual

            </Nav.Link>

            <Nav.Link
              as={Link}
              to="/Insightnews"
              className={`nav-link boldname hovereffect ${
                location.pathname === "/Insightnews" ? "active" : ""
              }`}
              onClick={() => setExpanded(false)}
            >
          Insights/News
            </Nav.Link>
          
            <Nav.Link
              as={Link}
              to="/Productlist"
              className={`nav-link boldname hovereffect ${
                location.pathname === "/Productlist" ? "active" : ""
              }`}
              onClick={() => setExpanded(false)}
            >
              Services
            </Nav.Link>
            {/* <NavDropdown
              title="Products"
              id="basic-nav-dropdown"
              className="nav-link product-dropdown boldname"
              show={showDropdown}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              // onClick={handleDropdownClick}
            >
              <NavDropdown.Item
                as={Link}
                to="Productlist"
                onClick={handleDropdownClick}
              >
                Buy & Rent
              </NavDropdown.Item>
              <NavDropdown.Item
                as={Link}
                to="/Our-Container"
                onClick={handleDropdownClick}
              >
                Our Containers
              </NavDropdown.Item>
            </NavDropdown> */}
        
        <Nav.Link
              as={Link}
              to="/contact-us"
              className={`nav-link boldname hovereffect ${
                location.pathname === "/contact-us" ? "active" : ""
              }`}
              onClick={() => setExpanded(false)}
            >
              Contact
            </Nav.Link>

            {/* <Nav.Link
              as={Link}
              to="/Sign-In"
              className="nav-link boldname hovereffect"
              onClick={() => setExpanded(false)} // Close navbar when link is clicked
            >
              Login
            </Nav.Link> */}

            {/* <Nav.Link
              as={Link}
              to="/Sign-Up"
              className="nav-link boldname hovereffect"
              onClick={() => setExpanded(false)} // Close navbar when link is clicked
            >
              Register
            </Nav.Link> */}


       

            <Nav.Link
              
              
              className="nav-link boldname hovereffect "
              onClick={handleProfileNavigation}// Close navbar when link is clicked
            >
            {/* <h6 className="  profilenamee">
              Profile
              </h6> */}
            

              <div className="">
                {Profile && (
                  <div className="d-flex align-items-center ">
                    <img
                      src={
                        Profile.u_profile_pic
                         
                          ? `https://lunarsenterprises.com:4002/${Profile.u_profile_pic}`
                          : "https://cdn.easyfrontend.com/pictures/team/team_square_3.jpeg"
                      }
                      alt="User Profile"
                      className="rounded-circleprofile"
                      // width="70"
                      // height="70"
                      
                     
                      style={{ cursor: "pointer" }}
                    />
                  
                 
                    
                 
                  </div>
                )}
              </div>

            </Nav.Link>


          </Nav>
        </Navbar.Collapse>
      </div>
    </Navbar>
  );
};

export default Header;
