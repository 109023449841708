import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarCheck,
  faChevronRight,
  faClock,
  faDollarSign,
  faGift,
  faHeart,
  faIdCard,
  faReceipt,
  faShippingFast,
  faUsers,
  faWallet,
} from "@fortawesome/free-solid-svg-icons";
import PropTypes from "prop-types";
import "../Profile/Profile.css";
import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";
import Select from "react-select";
import { Button, Col, Form, Spinner } from "react-bootstrap";
import { Country, State, City } from "country-state-city";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { data } from "autoprefixer";

const sidebarData = [
  {
    type: "link",
    title: "Overview",
  },
  {
    type: "link",
    title: "Orders",
  },
  {
    type: "link",
    title: "Payment",
  },
  {
    type: "link",
    title: "Refund & Return",
  },
  {
    type: "link",
    title: "Feedback",
  },
  {
    type: "link",
    title: "Setting",
  },

  {
    type: "divider",
  },
  {
    type: "link",
    title: "Invite friends",
  },
  {
    type: "divider",
  },

  {
    type: "link",
    title: "Help Center",
  },
  {
    type: "link",
    title: "Manage reports",
  },
  {
    type: "link",
    title: "Suggestion",
  },
  {
    type: "link",
    title: "DS center",
  },
];

const profileList = [
  {
    icon: faHeart,
    text: "Wish List",
  },
  {
    icon: faUsers,
    text: "Following",
  },
  {
    icon: faClock,
    text: "Viewed",
  },
  {
    icon: faIdCard,
    text: "Coupons",
  },
];

const ordersList = [
  {
    icon: faWallet,
    text: "Unpaid",
  },
  {
    icon: faGift,
    text: "To Be Shipped",
  },
  {
    icon: faShippingFast,
    text: "Shipped",
  },
  {
    icon: faCalendarCheck,
    text: "To Be Reviewed",
  },
];

const links = [
  {
    icon: faReceipt,
    text: "My Appeal",
  },
  {
    icon: faDollarSign,
    text: "In dispute",
  },
];

const SideBar = () => {
  const [value, setValue] = useState(0);

  return (
    <div className="bg-white shadow py-4">
      <h5 className="text-xl font-bold mx-3 mb-3">Account</h5>

      <ul className="nav flex-column">
        {sidebarData.map((item, i) => {
          if (item.type === "divider") {
            return <hr className="my-2" key={i} />;
          }

          return (
            <li
              className={`nav-item px-3 py-2 ${
                i === value && "active bg-light border-start border-primary"
              }`}
              key={i}
              onClick={() => setValue(i)}
            >
              <a
                href="src/components/bootstrap/categories/eProfile/EPProfile"
                className="nav-link"
              >
                {item.title}sdsdnsja
              </a>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

const Profile = ({ list }) => {
  return (
    <a href="!#" className="text-center d-block">
      <div className="mb-2 text-muted">
        <FontAwesomeIcon icon={list.icon} size="2x" />
      </div>
      <p className="h6">{list.text}</p>
    </a>
  );
};

Profile.propTypes = {
  list: PropTypes.object.isRequired,
};

const Orders = ({ list }) => {
  return (
    <div className="text-center">
      <div className="text-primary mb-3">
        <FontAwesomeIcon icon={list.icon} size="3x" />
      </div>
      <p className="h6">{list.text}</p>
    </div>
  );
};

Orders.propTypes = {
  list: PropTypes.object.isRequired,
};

const Links = ({ link }) => {
  return (
    <>
      <hr className="my-4" />
      <a
        href="#!"
        className="d-flex align-items-center justify-content-between text-decoration-none"
      >
        <div className="d-flex align-items-center">
          <FontAwesomeIcon icon={link.icon} />
          <p className="mb-0 ml-2">{link.text}qwdwsqws</p>
        </div>
        <FontAwesomeIcon icon={faChevronRight} />
      </a>
    </>
  );
};

Links.propTypes = {
  link: PropTypes.object.isRequired,
};

const Overview = () => {
  const [address, setAddress] = useState({
    Address: "",
    Landmark: "",
    phoneNum: "",
    Country: "",
    state: "",
    city: "",
    zip: "",
  });

  const [addressList, setAddressList] = useState([]); // State to hold address list
  const [isEditing, setIsEditing] = useState(false); // State to control editing mode
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [errors, setErrors] = useState({});
  const [isLoading, setisLoading] = useState(false);
  const userid = localStorage.getItem("brooks_user_id");

  useEffect(() => {
    setCountries(
      Country.getAllCountries().map((country) => ({
        label: country.name,
        value: country.isoCode,
      }))
    );
    AddressViewList();
	 // Fetch addresses on initial render
  }, []);

  const handleCountryChange = (selectedCountry) => {
    if (selectedCountry) {
      setAddress({ ...address, Country: selectedCountry.label });
      setStates(
        State.getStatesOfCountry(selectedCountry.value).map((state) => ({
          label: state.name,
          value: state.isoCode,
        }))
      );
    } else {
      setAddress({ ...address, Country: "", state: "", city: "" });
      setStates([]);
      setCities([]);
    }
  };

  const handleStateChange = (selectedState) => {
    if (selectedState) {
      setAddress({ ...address, state: selectedState.label });
      setCities(
        City.getCitiesOfState(
          countries.find((c) => c.label === address.Country)?.value || "",
          selectedState.value
        ).map((city) => ({
          label: city.name || "",
          value: city.name || "",
        }))
      );
    } else {
      setAddress({ ...address, state: "", city: "" });
      setCities([]);
    }
  };

  const handleCityChange = (selectedCity) => {
    if (selectedCity) {
      setAddress({ ...address, city: selectedCity.label });
    } else {
      setAddress({ ...address, city: "" });
    }
  };

  const validateForm = () => {
    const newErrors = {};

    if (!address.Address) {
      newErrors.Address = "Address is required";
    } else if (address.Address.length < 5) {
      newErrors.Address = "Address must be at least 5 characters long";
    } else if (address.Address.length > 200) {
      newErrors.Address = "Address must be no more than 200 characters long";
    }

    if (!address.Landmark) newErrors.Landmark = "Landmark is required";
    else if (address.Landmark.length < 5) {
      newErrors.Landmark = "Landmark must be at least 5 characters long";
    } else if (address.Landmark.length > 300) {
      newErrors.Landmark = "Landmark must be no more than 300 characters long";
    }

    if (!address.phoneNum) newErrors.phoneNum = "Phone number is required";
    if (!address.Country) {
      newErrors.Country = "Country is required";
    } else if (!/^[A-Za-z\s]+$/.test(address.Country)) {
      newErrors.Country = "Country must contain only letters";
    }

    if (!address.state) {
      newErrors.state = "State is required";
    }

    if (!address.city) {
      newErrors.city = "City is required";
    } else if (!/^[A-Za-z\s]+$/.test(address.city)) {
      newErrors.city = "City must contain only letters";
    }

    if (!address.zip) {
      newErrors.zip = "Zip Code is required";
    } else if (!/^\d{5,15}$/.test(address.zip)) {
      newErrors.zip =
        "Zip Code must be between 5 and 15 digits long and contain only numbers";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setisLoading(true);

    if (!validateForm()) {
      setisLoading(false);
      return;
    }

    try {
      const response = await axios.post(
        "https://lunarsenterprises.com:4002/brookscontainer/address/add",
        {
          name: "demo",
          address: `${address.Address}, ${address.Landmark}, ${address.city}, ${address.state}, ${address.Country}, ${address.zip}, ${address.phoneNum}`,
        },
        {
          headers: {
            u_id: userid,
          },
        }
      );

      toast.success(response.data.message);
      // Refresh address list after adding
      setAddress({
        Address: "",
        Landmark: "",
        phoneNum: "",
        Country: "",
        state: "",
        city: "",
        zip: "",
      });
    } catch (error) {
      console.error("Failed to add address:", error);
    } finally {
      setisLoading(false);
    }
  };

  const AddressViewList = async () => {
    try {
      const response = await axios.post(
        "https://lunarsenterprises.com:4002/brookscontainer/user/address/list",
        {},
        { headers: { u_id: userid } }
      );

      // Map each address and split the ua_address into individual fields
      const addresses = response.data.data.map((address) => {
        const addressParts = address.ua_address
          .split(",")
          .map((part) => part.trim());

        // Logging each part to check if they are parsed correctly
        console.log("Parsed Address Parts:", addressParts);

        const [addressLine, landmark, city, state, country, zip, phone] =
          addressParts;

        return {
          ua_id: address.ua_id,

          address: addressLine,
          landmark,
          city,
          state,
          country,
          zip,
          phone,
        };
      });

      setAddressList(addresses);
    } catch (error) {
      console.error("Failed to fetch addresses:", error);
    }
  };

  const handleEdit = (addr) => {
    setAddress({
      Address: addr.address || "",
      Landmark: addr.landmark || "",
      phoneNum: addr.phone || "",
      Country: addr.country || "",
      state: addr.state || "",
      city: addr.city || "",
      zip: addr.zip || "",
      ua_id: addr.ua_id, // Include ua_id for updating
    });
    setIsEditing(true);
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();
    setisLoading(true);

    if (!validateForm()) {
      setisLoading(false);
      console.log("Form validation failed:", errors);
      return;
    }

    try {
      const formData = {
        ua_id: address.ua_id || "",
        name: "demo",

        address: `${address.Address || ""}, ${address.Landmark || ""}, ${
          address.city || ""
        }, ${address.state || ""}, ${address.Country || ""}, ${
          address.zip || ""
        }, ${address.phoneNum || ""}`,
      };

      console.log("User ID:", userid);

      const response = await axios({
        url: "https://lunarsenterprises.com:4002/brookscontainer/address/edit",
        method: "post",
        headers: {
          u_id: userid || "",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        data: formData,
      });

      toast.success(response.data.message);
      setIsEditing(false);
      setAddress({
        listaddress: "",
        Landmark: "",
        phoneNum: "",
        Country: "",
        state: "",
        city: "",
        zip: "",
      });
	  AddressViewList()
    } catch (error) {
      console.error("Failed to edit address:", error.message || error);
      if (error.response) {
        console.log("Error response data:", error.response.data);
        toast.error(error.response.data.message || "Failed to update address");
      } else {
        toast.error("Failed to update address");
      }
    } finally {
      setisLoading(false);
    }
  };

  return (
    <section className="py-3 bg-light text-dark">
      <ToastContainer />
      <div className="container">
        {addressList.length === 0 ? (
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="Address">
              <Form.Label>Address</Form.Label>
              <Form.Control
                type="text"
                placeholder="123 Main St"
                name="Address"
                value={address.Address}
                onChange={(e) =>
                  setAddress({ ...address, address: e.target.value })
                }
                isInvalid={!!errors.Address}
              />
              <Form.Control.Feedback type="invalid">
                {errors.Address}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="Landmark" className="mt-2">
              <Form.Label>Landmark</Form.Label>
              <Form.Control
                type="text"
                placeholder="Landmark"
                name="Landmark"
                value={address.Landmark}
                onChange={(e) =>
                  setAddress({ ...address, Landmark: e.target.value })
                }
                isInvalid={!!errors.Landmark}
              />
              <Form.Control.Feedback type="invalid">
                {errors.Landmark}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="phoneNum" className="mt-2">
              <Form.Label>Contact Number</Form.Label>
              <PhoneInput
                country={"us"}
                value={address.phoneNum}
                onChange={(phone) =>
                  setAddress({ ...address, phoneNum: phone })
                }
                inputProps={{
                  required: true,
                  isInvalid: !!errors.phoneNum,
                }}
              />
              {errors.phoneNum && (
                <div className="invalid-feedback d-block">
                  {errors.phoneNum}
                </div>
              )}
            </Form.Group>

            <Form.Group controlId="Country" className="mt-2">
              <Form.Label>Country</Form.Label>
              <Select
                options={countries}
                onChange={handleCountryChange}
                placeholder="Select Country"
                isClearable
                value={countries.find((c) => c.label === address.Country)}
              />
              {errors.Country && (
                <div className="invalid-feedback d-block">{errors.Country}</div>
              )}
            </Form.Group>

            <Form.Group controlId="State" className="mt-2">
              <Form.Label>State</Form.Label>
              <Select
                options={states}
                onChange={handleStateChange}
                placeholder="Select State"
                isClearable
                value={states.find((s) => s.label === address.state)}
              />
              {errors.state && (
                <div className="invalid-feedback d-block">{errors.state}</div>
              )}
            </Form.Group>

            <Form.Group controlId="City" className="mt-2">
              <Form.Label>City</Form.Label>
              <Select
                options={cities}
                onChange={handleCityChange}
                placeholder="Select City"
                isClearable
                value={cities.find((c) => c.label === address.city)}
              />
              {errors.city && (
                <div className="invalid-feedback d-block">{errors.city}</div>
              )}
            </Form.Group>

            <Form.Group controlId="zip" className="mt-2">
              <Form.Label>Zip Code</Form.Label>
              <Form.Control
                type="number"
                placeholder="Zip Code"
                name="zip"
                value={address.zip}
                onChange={(e) =>
                  setAddress({ ...address, zip: e.target.value })
                }
                isInvalid={!!errors.zip}
              />
              <Form.Control.Feedback type="invalid">
                {errors.zip}
              </Form.Control.Feedback>
            </Form.Group>

            <Button className="mt-4" type="submit">
              {isLoading ? (
                <div
                  className=" justify-center align-items-center d-flex"
                  role="status"
                >
                  <Spinner
                    animation="grow"
                    role="status"
                    className="me-2"
                  ></Spinner>
                  <span>Submit...</span>
                </div>
              ) : (
                <span>Submit</span>
              )}
            </Button>
          </Form>
        ) : (
          <Form>
            <Form.Group controlId="Address">
              <Form.Label>Address</Form.Label>
              <Form.Control
                type="text"
                placeholder="123 Main St"
                name="Address"
                value={addressList[0]?.address || ""}
              />
            </Form.Group>

            <Form.Group controlId="landmark" className="mt-2">
              <Form.Label>Landmark</Form.Label>
              <Form.Control
                type="text"
                placeholder="Landmark"
                name="landmark"
                value={addressList[0]?.landmark || ""}
              />
            </Form.Group>

            <Form.Group controlId="phone" className="mt-2">
              <Form.Label>Contact Number</Form.Label>
              <Form.Control
                type="text"
                placeholder="Contact Number"
                name="phone"
                value={addressList[0]?.phone || ""}
              />
            </Form.Group>

            <Form.Group controlId="Country" className="mt-2">
              <Form.Label>Country</Form.Label>

              <Form.Control
                type="text"
                placeholder="Country"
                name="country"
                value={addressList[0]?.country}
              />
            </Form.Group>

            <Form.Group controlId="state" className="mt-2">
              <Form.Label>State</Form.Label>

              <Form.Control
                type="text"
                placeholder="state"
                name="state"
                value={addressList[0]?.state}
              />
            </Form.Group>

            <Form.Group controlId="city" className="mt-2">
              <Form.Label>City</Form.Label>

              <Form.Control
                type="text"
                placeholder="state"
                name="city"
                value={addressList[0]?.city}
              />
            </Form.Group>

            <Form.Group controlId="zip" className="mt-2">
              <Form.Label>Zip Code</Form.Label>

              <Form.Control
                type="number"
                placeholder="Zip Code"
                name="zip"
                value={addressList[0]?.zip}
              />
            </Form.Group>
          </Form>
        )}

        <div>
          {addressList.map((addr, index) => (
            <div key={index} className="address-item">
              <p>{addr.Address}</p>
              <Button variant="secondary" onClick={() => handleEdit(addr)}>
                Edit
              </Button>
            </div>
          ))}
        </div>

        {isEditing && (
          <Form onSubmit={handleEditSubmit}>
            <Form.Group controlId="Address">
              <Form.Label>Address</Form.Label>
              <Form.Control
                type="text"
                placeholder="123 Main St"
                name="Address"
                value={address.Address} // Correctly bound to address state
                onChange={(e) =>
                  setAddress({ ...address, Address: e.target.value })
                }
                isInvalid={!!errors.Address}
              />
              <Form.Control.Feedback type="invalid">
                {errors.Address}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="Landmark" className="mt-2">
              <Form.Label>Landmark</Form.Label>
              <Form.Control
                type="text"
                placeholder="Landmark"
                name="Landmark"
                value={address.Landmark}
                onChange={(e) =>
                  setAddress({ ...address, Landmark: e.target.value })
                }
                isInvalid={!!errors.Landmark}
              />
              <Form.Control.Feedback type="invalid">
                {errors.Landmark}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="phoneNum" className="mt-2">
              <Form.Label>Contact Number</Form.Label>
              <PhoneInput
                country={"us"}
                value={address.phoneNum}
                onChange={(phone) =>
                  setAddress({ ...address, phoneNum: phone })
                }
                inputProps={{
                  required: true,
                  isInvalid: !!errors.phoneNum,
                }}
              />
              {errors.phoneNum && (
                <div className="invalid-feedback d-block">
                  {errors.phoneNum}
                </div>
              )}
            </Form.Group>

            <Form.Group controlId="Country" className="mt-2">
              <Form.Label>Country</Form.Label>
              <Select
                options={countries}
                onChange={handleCountryChange}
                placeholder="Select Country"
                isClearable
                value={countries.find((c) => c.label === address.Country)}
              />
              {errors.Country && (
                <div className="invalid-feedback d-block">{errors.Country}</div>
              )}
            </Form.Group>

            <Form.Group controlId="State" className="mt-2">
              <Form.Label>State</Form.Label>
              <Select
                options={states}
                onChange={handleStateChange}
                placeholder="Select State"
                isClearable
                value={states.find((s) => s.label === address.state)}
              />
              {errors.state && (
                <div className="invalid-feedback d-block">{errors.state}</div>
              )}
            </Form.Group>

            <Form.Group controlId="City" className="mt-2">
              <Form.Label>City</Form.Label>
              <Select
                options={cities}
                onChange={handleCityChange}
                placeholder="Select City"
                isClearable
                value={cities.find((c) => c.label === address.city)}
              />
              {errors.city && (
                <div className="invalid-feedback d-block">{errors.city}</div>
              )}
            </Form.Group>

            <Form.Group controlId="zip" className="mt-2">
              <Form.Label>Zip Code</Form.Label>
              <Form.Control
                type="number"
                placeholder="Zip Code"
                name="zip"
                value={address.zip}
                onChange={(e) =>
                  setAddress({ ...address, zip: e.target.value })
                }
                isInvalid={!!errors.zip}
              />
              <Form.Control.Feedback type="invalid">
                {errors.zip}
              </Form.Control.Feedback>
            </Form.Group>

            <Button className="mt-4" type="submit">
              {isLoading ? (
                <div
                  className=" justify-center align-items-center d-flex"
                  role="status"
                >
                  <Spinner
                    animation="grow"
                    role="status"
                    className="me-2"
                  ></Spinner>
                  <span>Update...</span>
                </div>
              ) : (
                <span>Update</span>
              )}
            </Button>
          </Form>
        )}
      </div>
    </section>
  );
};

export default Overview;
