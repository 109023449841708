import React from "react";
import { motion } from "framer-motion";
import { FaUser, FaBox, FaCreditCard, FaEnvelope, FaPhone, FaHandshake } from "react-icons/fa";

import "../Howtowork/Howtowork.css";
import homepagevideo from "../../Components/images/Brooksedited.mp4";

const HowtoCarousel = () => {

const steps = [
  {
    id: 1,
    title: "Enter Your Personal Details",
    icon: <FaUser />, // Icon for personal details
    description: "Provide your name, contact details, and other necessary information.",
  },
  {
    id: 2,
    title: "Select Your Assets",
    icon: <FaBox />, // Icon for selecting assets
    description: "Choose the assets you wish to purchase or rent from the available options.",
  },
  {
    id: 3,
    title: "Choose Payment Mode",
    icon: <FaCreditCard />, // Icon for payment options
    description: "Select a convenient payment method to proceed with the transaction.",
  },
  {
    id: 4,
    title: "Agreement Sent via Email",
    icon: <FaEnvelope />, // Icon for email agreement
    description: "You will receive the agreement and details of the transaction in your email.",
  },
  {
    id: 5,
    title: "Representative Will Call You",
    icon: <FaPhone style={{ transform: "rotate(100deg)" }} />,
    description: "Our representative will contact you to finalize the details and answer queries.",
  },
  {
    id: 6,
    title: "Deal Successfully Closed",
    icon: <FaHandshake />, // Icon for successful deal
    description: "The transaction is completed, and your assets are ready for use.",
  },
];


  const handleVideoClick = () => {
    window.open(
      "https://player.vimeo.com/video/1029593628?h=3c607d7b66",
      "_blank"
    );
  };

  return (
    <div className="container mt-4">
      <h2 className="timeline-heading text-center mb-5">
        How to Get a Container
      </h2>

      <div className="row">
        {/* Timeline Steps */}
        <div className="col-md-5 mb-4">
          <ul className="timeline-list">
            {steps.map((step, index) => (
              <motion.li
                key={step.id}
                className="timeline-list-item"
                initial={{ opacity: 0, x: -50 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: index * 0.2 }}
                whileHover={{ scale: 1.05 }}
              >
                <div className="d-flex">
                <div className="timeline-icon">{step.icon}</div>
                <div className="timeline-text">
                  <h4 className="timeline-step-title">{step.title}</h4>
                  <p className="timeline-step-description">
                    {step.description}
                  </p>
                  </div>
                </div>
              </motion.li>
            ))}
          </ul>
        </div>

        {/* Video Section */}
        <div className="col-md-7">
          <div
            className="video-container position-relative"
            onClick={handleVideoClick}
          >
            <motion.div
              className="play-button-overlay"
              whileHover={{ scale: 1.2 }}
            >
              ▶
            </motion.div>
            <video
              src={homepagevideo}
              className="video-background rounded shadow-lg"
              loop
              muted
              autoPlay
              playsInline
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowtoCarousel;
