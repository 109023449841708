import React from "react";
// import FAQ from "../../Components/FAQ/FAQ";
import { motion } from "framer-motion";

import "../Howtowork/Howtowork.css";

// import Tabscomponent from "./Tabs";
import Marketnew from "../../Components/LIveMarket/Livemarket";
import { useNavigate } from "react-router-dom";
import NewsView from "./NewsView";
export default function Insightnews() {
  const containerVariants = {
    hidden: { opacity: 0, x: -50 },
    visible: { opacity: 1, x: 0 },
  };
  const navi = useNavigate();
  return (
    <div>
      <section>
        <header className="hero-sectionho">
          <Marketnew />
        </header>
      </section>

      <section>
    </section>

    <NewsView/>

          {/* Free Consultation Section */}
          <div className="free-consultation">
        <h2>Free Consultation</h2>
        <button onClick={() => navi("/contact-us")} className="consult-button"> 
           Contact Us
        </button> 
      </div>
    </div>
  );
}
