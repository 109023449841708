import React, { useState } from "react";
import HowtoCarousel from "./HowtoCarousel";
import "../Howtowork/Howtowork.css";
import dummyimgtwo from "../../Pages/images/transtwo.jpg";
import Paper from "../../Pages/images/White-Paper-Gibraltar_01.png";
import infographic from "../../Pages/images/infographic.png";
import { useNavigate } from "react-router-dom";

const Tabscomponent = () => {
  const [activeTab, setActiveTab] = useState("tab1");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  // Scroll the active tab into view

  const navi = useNavigate();

  return (
    <div className="">
      <HowtoCarousel />

         {/* Free Consultation Section */}
         <div className="free-consultation">
        <h2>Free Consultation</h2>
        <button onClick={() => navi("/contact-us")} className="consult-button"> 
           Contact Us
        </button> 
      </div>
    </div>
  );
};

export default Tabscomponent;
