import React, { Fragment, useEffect, useState } from "react";
import { Button, Carousel, Col, Container, Nav, Row } from "react-bootstrap";
import PropTypes from "prop-types";
import axios from "axios";
import { useParams } from "react-router-dom";
import "../Selectcontainer/Selectcontainer.css";
import TimelineForm from "../Timeline/Timeline";
import dummy from "../images/infographic.png";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDollarSign,
  faRulerCombined,
  faFileSignature,
  faTruck,
  faCog,
  faMoneyCheckAlt,
  faReceipt,
  faArrowUp,
  faArrowLeftLong,
  faWarehouse,
} from "@fortawesome/free-solid-svg-icons";
import { useMediaQuery } from 'react-responsive';

const ProductPreviews = ({ previews }) => {
  const [index, setIndex] = useState(0);

  const fallbackImageUrl = "https://via.placeholder.com/300?text=No+Image"; // Fallback image URL
  const isMobile = useMediaQuery({ maxWidth: 768 });
  return (
    <Row className="margintop">
    <Col lg={12} className="">
      {isMobile ? (
        // Show Carousel on mobile
        <Carousel activeIndex={index} onSelect={(selectedIndex) => setIndex(selectedIndex)}>
          {previews.map((preview, i) => (
            <Carousel.Item key={i}>
              <img
                src={preview.previewUrl || dummy}
                alt={`Preview ${i}`}
                className="Mainimageco "
              />
            </Carousel.Item>
          ))}
        </Carousel>
      ) : (
        // Show standard layout for larger screens
        <div className="row">
         <Nav
  className="hide col-md-4 d-flex align-items-center justify-center ezy__epqoverview2-gallery custom-heightselect"
>
  {previews.map((preview, i) => (
    <li
      key={i}
      onClick={() => setIndex(i)}
      className={index === i ? "active-thumbnail" : ""}
    >
      <img
        src={preview.thumbUrl || dummy}
        alt={`preview ${i}`}
        className=" imagesidestyle"
      />
    </li>
  ))}
</Nav>

          <div className="col-md-8">
            <img
              src={previews[index]?.previewUrl || dummy}
              alt="Main Preview"
              className="Mainimage"
            />
          </div>
        </div>
      )}
    </Col>
  </Row>
  );
};

ProductPreviews.propTypes = {
  previews: PropTypes.array.isRequired,
};

const Selectcontainer = () => {
  const { c_id } = useParams();

  const [product, setProduct] = useState({
    title: "",
    price: 0,
    colorVariants: [],
    sizeVariants: [],
    previews: [],
  });

  const [sizeContainer, setSizeContainer] = useState([]);

  const [sizeContainermain, setSizeContainermain] = useState([]);

  useEffect(() => {
    const fetchProductData = async () => {
      try {
        const response = await axios.post(
          "https://lunarsenterprises.com:4002/brookscontainer/container/view",
          { c_id: c_id }
        );

        const productData = response.data.data[0];
        const productSize = response.data.data[0].size;

        const productSizemain = response.data.data[0].size;

        setSizeContainer(productSize || []);
        setSizeContainermain(productSizemain);

        const productsize = response.data.data[0].size;

        console.log(response.data.data[0].size, "response.data.data.size");

        setProduct({
          title: productData.c_name,
          price: productData.brand_new_price,
          colorVariants: [],
          sizeVariants: [],
          previews: productData.image.map((image) => ({
            previewUrl:
              `https://lunarsenterprises.com:4002/${image.ci_image}` || dummy,
            thumbUrl:
              `https://lunarsenterprises.com:4002/${image.ci_image}` || dummy,
          })),
        });
      } catch (error) {
        console.error("Error fetching product data:", error);
      }
    };

    fetchProductData();
  }, [c_id]);

  const [showTimelineForm, setShowTimelineForm] = useState(false);

  const handleToggleTimelineForm = () => {
    setShowTimelineForm((prev) => !prev);
  };

  return (
    <Fragment>
      <section className="margintopsec ezy__epqoverview2 dark py-5">
        <Container>
          <Row>
            <Col lg={7}>
              <ProductPreviews previews={product.previews} />
            </Col>


            <Col lg={5}>
              <div className="mt-4 mt-lg-5">
                <div className="mb-4 margintop">
                  <Col>
                    <h1 className=" ezy__epqoverview2 mb-0 text-capitalize">
                      {product.title}
                    </h1>
                  </Col>

                  {/* TimelineForm Section */}
                  {showTimelineForm ? (
                    <div>
                      <div className="mt-3">
                        <Button
                          className="back-button d-flex justify-center align-items-center mb-3"
                          onClick={handleToggleTimelineForm}
                          style={{ cursor: "pointer" }}
                        >
                          <FontAwesomeIcon
                            icon={faArrowLeftLong}
                            className="back-arrow-icon"
                            style={{
                              fontSize: "1.5rem",
                              color: "white",
                              marginRight: "0.5rem",
                            }}
                          ></FontAwesomeIcon>
                          {/* <h1 className="text-center" style={{ fontSize: "1rem", color: "white" ,paddingTop:8,display:'flex',alignItems:''}}>Back</h1> */}
                        </Button>
                      </div>

                      <TimelineForm />
                    </div>
                  ) : (
                    <div>
                      {sizeContainermain && sizeContainermain.length > 0 ? (
                        sizeContainermain.map(
                          (item, index) =>
                            index % 2 === 0 && ( // Create a new Row for every two items
                              <Row
                                key={index}
                                className="mb-4 mt-3 p-3"
                                style={{
                                  border: "1px solid #ddd",
                                  borderRadius: "8px",
                                  backgroundColor: "#f9f9f9",
                                }}
                              >
                                <Col xs={12} lg={6} className="p-2">
                                  <h3 className="ezy__epqoverview2-price mb-3 text-nowrap sizemedia">
                                    <FontAwesomeIcon icon={faDollarSign} />{" "}
                                    Price: ${item.cs_brand_new_price}
                                    <br />
                                    <FontAwesomeIcon icon={faReceipt} /> Rental
                                    Price/Year: <br />${item.cs_rental_cost}
                                  </h3>
                                  <p>
                                    <FontAwesomeIcon icon={faFileSignature} />{" "}
                                    Specification: {item.cs_body_type}
                                  </p>
                                  <p>
                                    <FontAwesomeIcon icon={faRulerCombined} />{" "}
                                    Size: {item.cs_size} ft
                                  </p>
                                  <p>
                                    <FontAwesomeIcon icon={faTruck} />{" "}
                                    Condition: {item.cs_condition}
                                  </p>
                                  <p>
                                    <FontAwesomeIcon icon={faCog} /> Parking
                                    Charge (10% Yearly): $
                                    {item.cs_parking_charge}
                                  </p>
                                  <p>
                                    <FontAwesomeIcon icon={faMoneyCheckAlt} />{" "}
                                    Service Fee (Year): ${item.cs_service_fee}
                                  </p>

                                  {/* <p>
                                    <FontAwesomeIcon icon={faWarehouse} />{" "}
                                    {item.cs_stock === 0 ? (
                                      <span style={{ color: "red" }}>
                                        Stock Not Available
                                      </span>
                                    ) : (
                                      <span style={{ color: "green" }}>
                                        Stocks: {item.cs_stock}
                                      </span>
                                    )}
                                  </p> */}
                                  <p>
                                    <strong>
                                      Total Cost: ${item.cs_total_cost}
                                    </strong>
                                  </p>
                                </Col>

                                {/* Check if there's a next item to display in the second column */}
                                {sizeContainermain[index + 1] && (
                                  <Col xs={12} lg={6} className="p-2">
                                    <h3 className="ezy__epqoverview2-price mb-3 text-nowrap sizemedia">
                                      <FontAwesomeIcon icon={faDollarSign} />{" "}
                                      Price: $
                                      {
                                        sizeContainermain[index + 1]
                                          .cs_brand_new_price
                                      }
                                      <br />
                                      <p>
                                        <FontAwesomeIcon icon={faReceipt} />{" "}
                                        Rental Price/Year:
                                        <br />$
                                        {
                                          sizeContainermain[index + 1]
                                            .cs_rental_cost
                                        }
                                      </p>
                                    </h3>
                                    <p>
                                      <FontAwesomeIcon icon={faFileSignature} />{" "}
                                      Specification:{" "}
                                      {
                                        sizeContainermain[index + 1]
                                          .cs_body_type
                                      }
                                    </p>

                                    <p>
                                      <FontAwesomeIcon icon={faRulerCombined} />{" "}
                                      Size:{" "}
                                      {sizeContainermain[index + 1].cs_size} ft
                                    </p>
                                    <p>
                                      <FontAwesomeIcon icon={faTruck} />{" "}
                                      Condition:{" "}
                                      {
                                        sizeContainermain[index + 1]
                                          .cs_condition
                                      }
                                    </p>
                                    <p>
                                      <FontAwesomeIcon icon={faCog} /> Parking
                                      Charge (10% Yearly): $
                                      {
                                        sizeContainermain[index + 1]
                                          .cs_parking_charge
                                      }
                                    </p>
                                    <p>
                                      <FontAwesomeIcon icon={faMoneyCheckAlt} />{" "}
                                      Service Fee (Year): $
                                      {
                                        sizeContainermain[index + 1]
                                          .cs_service_fee
                                      }
                                    </p>

                                    {/* <p>
                                      <FontAwesomeIcon icon={faWarehouse} />{" "}
                                      {sizeContainermain[index + 1].cs_stock ===
                                      0 ? (
                                        <span style={{ color: "red" }}>
                                          Stock Not Available
                                        </span>
                                      ) : (
                                        <span style={{ color: "green" }}>
                                          Stocks:{" "}
                                          {
                                            sizeContainermain[index + 1]
                                              .cs_stock
                                          }
                                        </span>
                                      )}
                                    </p> */}
                                    <p>
                                      <strong>
                                        Total Cost: $
                                        {
                                          sizeContainermain[index + 1]
                                            .cs_total_cost
                                        }
                                      </strong>
                                    </p>
                                  </Col>
                                )}
                                <Button
                                  variant="primary"
                                  onClick={handleToggleTimelineForm}
                                  style={{ marginTop: "20px" }}
                                >
                                  Buy/Rent Now
                                </Button>
                              </Row>
                            )
                        )
                      ) : (
                        <p>No specifications available</p>
                      )}
                    </div>
                  )}
                </div>
              </div>
              {/* <TimelineForm /> */}
            </Col>
          </Row>
        </Container>
      </section>
    </Fragment>
  );
};

export default Selectcontainer;
