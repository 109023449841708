import React, { useEffect, useState } from "react";
import { Card, Col, Container, Nav, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faInstagram,
  faLinkedinIn,
  faTwitter,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";
import { faBookmark, faShareAlt } from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";
import PropTypes from "prop-types";
import axios from "axios";

import "../Insightnews/Insightnews.css";

const social = [
  { icon: faFacebook, href: "#!" },
  { icon: faInstagram, href: "#!" },
  { icon: faLinkedinIn, href: "#!" },
  { icon: faTwitter, href: "#!" },
  { icon: faWhatsapp, href: "#!" },
  { icon: faShareAlt, href: "#!" },
  { icon: faBookmark, href: "#!" },
];

const BlogItem = ({ item }) => {
  return (
    <div className="blog-item d-flex justify-content-between align-items-start">
      <img src={item.urlToImage} alt="" className="img-fluid rounded" />
      <div className="ms-3">
        <h6>{item.title}</h6>
        <div className="d-flex flex-wrap opacity-50">
          <span className="me-3">
            {new Date(item.publishedAt).toLocaleDateString()}
          </span>
          <span>
            By <b>{item.author || "Unknown"}</b>
          </span>
        </div>
      </div>
    </div>
  );
};

BlogItem.propTypes = {
  item: PropTypes.object.isRequired,
};

const Contents = ({ content }) => (
  <div className="news-content mt-4">
    {content.map((paragraph, index) => (
      <p key={index}>{paragraph}</p>
    ))}
  </div>
);

const Social = ({ list, index }) => (
  <a href={list.href} key={index}>
    <FontAwesomeIcon
      icon={list.icon}
      className={classNames({ "ms-3": index })}
    />
  </a>
);

const SocialContent = () => (
  <div className="social-content d-flex justify-content-between my-4">
    <Nav className="quick-links">
      {social.map((list, j) => (
        <Social list={list} index={j} key={j} />
      ))}
    </Nav>
  </div>
);

const SideBar = ({ blogs }) => (
  <Card className="popular-blogs border-0">
    <Card.Header className="py-3 border-0">
      <h5 className="mb-0">Popular Blogs</h5>
    </Card.Header>
    <Card.Body className="py-4">
      {blogs.map((item, i) => (
        <React.Fragment key={i}>
          {!!i && <hr className="my-4" />}
          <BlogItem item={item} />
        </React.Fragment>
      ))}
    </Card.Body>
  </Card>
);

const NewsView = () => {
  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(
          "https://lunarsenterprises.com:4002/brookscontainer/container/news/api"
        );

        if (response.data.result && response.data.data) {
          const filteredArticles = response.data.data.articles.filter(
            (article) =>
              !article.title.includes("[Removed]") &&
              !article.description.includes("[Removed]")
          );
          setArticles(filteredArticles);
        } else {
          setError("Error retrieving data.");
        }
      } catch (err) {
        setError("Failed to fetch data.");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <section className="news-section light ">
      <Container className="mt-5  justify-contents-center align-align-items-center d-flex ">
        <Row>
          <Col xs={12} md={12} className="center-section ">
            <h1 className="heading">Latest News</h1>

            {loading && <p>Loading...</p>}
            {error && <p>{error}</p>}

            {!loading && !error && articles.length > 0 ? (
              articles.map((article, index) => (
                <div key={index} className="news-article">
                  <SocialContent />

                  <p>
                    {new Date(article.publishedAt).toLocaleDateString("en-US", {
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                    })}
                  </p>

                  {article.urlToImage && (
                    <img
                      src={article.urlToImage}
                      alt={article.title}
                      className="img-fluid rounded mb-3"
                    />
                  )}
                  <h3 className="news-title">{article.title}</h3>
                  <Contents content={[article.description]} />
                  <a
                    href={article.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="btn btn-primary mt-3"
                  >
                    Read more
                  </a>
                </div>
              ))
            ) : (
              <p>No articles available.</p>
            )}
          </Col>
          {/* <Col xs={12} md={4}> */}
          {/* <SideBar blogs={articles} /> */}
          {/* </Col> */}
        </Row>
      </Container>
    </section>
  );
};

export default NewsView;
