import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import "../Productslist/Productlist.scss";
import dummyimg from "../images/transport.jpg";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Customloader from "../../Components/Customloader/Customloader";

const Productlist = () => {
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState("sales"); // Default active tab set to 'sales'
  const [salesContainer, setsalesContainer] = useState([]);

  const navi = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000); // Loader will be shown for 3 seconds
  }, []);

  useEffect(() => {
    // Fetch data whenever the activeTab changes
    handleBuy();
  }, [activeTab]); // Re-run this effect when activeTab changes

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const handleBuy = async () => {
    try {
      const container_type = {
        type: activeTab,
      };
      const response = await axios.post(
        "https://lunarsenterprises.com:4002/brookscontainer/container/list",
        container_type
      );
      setsalesContainer(response.data.data);
    } catch (error) {
      console.error("Error fetching containers", error);
    }
  };

  const containerVariants = {
    hidden: { opacity: 0, x: -50 },
    visible: { opacity: 1, x: 0 },
  };

  const getPreviewImage = (images) => {
    const previewImage = images.find((img) => img.ci_image_priority === 0);
    return previewImage
      ? `https://lunarsenterprises.com:4002/${previewImage.ci_image}`
      : dummyimg;
  };

  const handleclick = (c_id) => {
    navi(`/Selected-container/${c_id}`);

    console.log(c_id);
  };

  const handleclickbuy = (c_id) => {
    navi(`/Rental-container/${c_id}`);

    console.log(c_id);
  };

  return (
    <>
      {/* {loading ? (
        <Customloader type="RotateLoader" loading={loading} />
      ) : ( */}
        <div className="products-page">
          {/* Hero Section */}
          <section className="hero-section">
            <motion.div
              initial="hidden"
              animate="visible"
              variants={containerVariants}
              transition={{ duration: 1 }}
              className="hero-contentprdt mt-5"
            >
              <h2>Premium Shipping Containers for Buy/Rent and Lease</h2>
              <p>
                Durable, versatile, and affordable solutions for all your
                shipping needs.
              </p>
            </motion.div>
          </section>

          {/* Tab Content */}
          <section className="product-section">
            {activeTab === "sales" && (
              <motion.div
                initial="hidden"
                animate="visible"
                variants={containerVariants}
                transition={{ duration: 0.5 }}
                // onClick={() => navi("/Selected-container")}
              >
                {salesContainer?.length === 0 ? (
                  <p className="no-container-message">No container found.</p>
                ) : (
                  <div className="product-list m-2">
                    {salesContainer?.map((item, index) => (
                      <motion.div
                        key={index}
                        className=""
                        transition={{ duration: 1.2 }}
                onClick={() => handleclick(item.c_id)}

                      >
                        <img
                          src={`https://lunarsenterprises.com:4002/${item.image}`}
                          className="prdt-img"
                          alt={item.c_name}
                        />

                        {/* <div></div> */}
                        <div >
                          <div className=" d-flex justify-content-center align-items-center " >
                        <p className="  text-capitalize textstyle ">
                          {item.c_name}
                        </p>
                        </div>
                        <div className=" d-flex justify-content-center align-items-center ">
                        <p className="price-container">
                          <span className="price-label">Brand New Price:{" "}

                          </span>
                          ${item.brand_new_price}
                        </p>
                        </div>
<div className=" d-flex justify-content-center align-items-center ">
                        <p className="price-container">
                          <span className="price-label">
                            Rental Price/Year:{" "}
                          </span>
                          ${item.rental_cost}
                        </p>
                        </div>
<div className=" d-flex justify-content-center align-items-center ">

                        <p className="price-container">
                          <span className="price-label">
                          Parking Charge/Year:{" "}
                          </span>
                          ${item.parking_charge}
                        </p>
                        </div>

                        <div className=" d-flex justify-content-center align-items-center ">
                        <p className="price-container">
                          <span className="price-label">
                          Service Fee/Year:{" "}
                          </span>
                          ${item.service_fee}
                        </p>
                        </div>

                        <div
                          onClick={() => handleclick(item.c_id)}
                          className="cta-buttonu"
                        >
                          <p className="buynow p-2">Buy & Rent Now</p>
                        </div>
                        </div>
                      </motion.div>
                    ))}
                  </div>
                )}
              </motion.div>
            )}

            {activeTab === "rental" && (
              <motion.div
                initial="hidden"
                animate="visible"
                variants={containerVariants}
                transition={{ duration: 0.5 }}
              >
                <div className="product-list">
                  {salesContainer?.map((item, index) => (
                    <motion.div
                      key={index}
                      className=" "
                      transition={{ duration: 1.2 }}
                    >
                      <img
                        src={`https://lunarsenterprises.com:4002/${item.image}`}
                        className="prdt-img"
                        alt={item.c_name}
                      />
                      <p className="text-capitalize textstyle ">
                        {item.c_name}
                      </p>
                      {/* <p>Available Size :</p> */}
                      {/* <p>
                        {item.size?.map((sizeItem, idx) => (
                          <span key={sizeItem.cs_id}>
                            {sizeItem.cs_size}ft
                            {idx < item.size.length - 1 && ", "}
                          </span>
                        ))}
                      </p> */}
                      <p class="price-container ">
                        <span class="price-label">Price: </span>${" "}
                        {item.brand_new_price}
                      </p>

                      <div
                        onClick={() => handleclick(item.c_id)}
                        className="cta-buttonu"
                      >
                        <p className="buynow p-2">Buy Now</p>
                      </div>
                    </motion.div>
                  ))}
                </div>
              </motion.div>
            )}
          </section>
        </div>
      {/* )} */}
    </>
  );
};

export default Productlist;
