import React, { useEffect, useRef, useState } from "react";
import "../LIveMarket/Livemarket.css"; // Ensure CSS file is correctly imported
import dummyimg from "../../Pages/images/transport.jpg"; 
import dummyimg3 from "../../Components/images/transportlogistic.jpg"; 
import dummyimg2 from "../../Components/images/Containerimg.jpg"; 


import axios from "axios";
import NewsView from "../../Pages/Insightnews/NewsView";

const Marketnew = () => {
  const [postIndex, setPostIndex] = useState(0);
  const [progress, setProgress] = useState(0);
  const [posts, setPosts] = useState([]);
  const progressIntervalRef = useRef(null);
  const postsRef = useRef(null); // Reference for the posts wrapper

  useEffect(() => {
    // Fetch data from the API when the component mounts
    axios
      .post("https://lunarsenterprises.com:4002/brookscontainer/container/news/api")
      .then((response) => {
        const data = response.data;
        if (data.result && data.data && data.data.articles) {
          setPosts(data.data.articles); // Fetch articles from the API response
        }
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  useEffect(() => {
    if (posts.length > 0) {
      startProgress();
      autoScroll();
    }
    return () => {
      clearInterval(progressIntervalRef.current);
      clearInterval(autoScrollIntervalRef.current);
    };
  }, [postIndex, posts]);

  const startProgress = () => {
    clearInterval(progressIntervalRef.current);
    setProgress(0);
    progressIntervalRef.current = setInterval(() => {
      setProgress((prev) => {
        if (prev >= 100) {
          clearInterval(progressIntervalRef.current);
          updatePostIndex();
          return 0;
        }
        return prev + 1;
      });
    }, 100); // Adjust this interval to control the speed of progress
  };

  const updatePostIndex = () => {
    setPostIndex((prev) => (prev + 1) % posts.length);
  };

  const handlePostClick = (index) => {
    setPostIndex(index);
    setProgress(0);
    startProgress();
  };

  // Auto scroll functionality
  const autoScrollIntervalRef = useRef(null);
  
  const autoScroll = () => {
    clearInterval(autoScrollIntervalRef.current);
    autoScrollIntervalRef.current = setInterval(() => {
      const currentPost = postsRef.current.children[postIndex];
      if (currentPost) {
        currentPost.scrollIntoView({ behavior: "smooth", block: "nearest" });
      }
      updatePostIndex(); // Move to the next post
    }, 50000); // Change this interval to adjust scroll frequency
  };

  const imageArray = [
    dummyimg,
    dummyimg2,
    dummyimg3,
    // Add more image URLs as needed
  ];
  

  return (
    <div className="carousell">
      <div className="progress-bar progress-bar--primary hide-on-desktop">
        <div className="progress-bar__fill" style={{ width: `${progress}%` }}></div>
      </div>

      <header className="main-post-wrapper">
        <div className="slides" ref={postsRef}>
          {posts.map((post, index) => (
            <article
              key={index}
              className={`main-post ${postIndex === index ? "main-post--active" : "main-post--not-active"}`}
            >
              <div className="main-post__image">
                {/* <img
                  src={post.urlToImage || dummyimg} // Default image if none provided
                  alt={post.title || "No title"}
                  loading="lazy"
                /> */}


<img
  src={imageArray[index % imageArray.length] || dummyimg} // Cycle through images in imageArray
  alt={post.title || "No title"}
  loading="lazy"
/>

              </div>
              <div className="main-post__content">
                <div className="main-post__tag-wrapper">
                  <span className="main-post__tag">{post.source?.name || "General"}</span>
                </div>
                <h1 className="main-post__title">{post.title || "Untitled Post"}</h1>
                <a
                  className="main-post__link"
                  href={post.url || "#"} // Default to "#" if no link
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {/* <span className="main-post__link-text">Find out more</span> */}
                </a>
              </div>
            </article>
          ))}
        </div>
      </header>

      <div className="posts-wrapper custom-height "  ref={postsRef}>
        {posts.map((post, index) => (
          <article
            key={index}
            className={`post ${postIndex === index ? "post--active" : ""}`}
            onClick={() => handlePostClick(index)}
          >
            <div className="progress-bar">
              <div className="progress-bar__fill" style={{ width: `${progress}%` }}></div>
            </div>
            <header className="post__header">
              <span className="post__tag">{post.source?.name || "General"}</span>
              <p className="post__published">
                {post.publishedAt ? new Date(post.publishedAt).toLocaleDateString() : "Unknown Date"}
              </p>
            </header>
            <h2 className="post__title">{post.title || "Untitled Post"}</h2>
          </article>
        ))}
      </div>

     
    </div>
  );
};

export default Marketnew;
