import React, { Fragment, useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  InputGroup,
  Row,
  Spinner,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faGoogle } from "@fortawesome/free-brands-svg-icons";
import {
  faArrowRight,
  faEye,
  faEyeSlash,
} from "@fortawesome/free-solid-svg-icons";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import loginimage from "../../Pages/images/loginsign.jpg";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import "../Login/Login.css";

// Social login buttons
const SocialLoginButton = () => (
  <Fragment>
    <Button variant="primary" className="w-100 d-flex align-items-center mb-3">
      <FontAwesomeIcon icon={faFacebook} className="fs-4 text-white" />
      <span className="w-100 text-center text-white">Continue with Facebook</span>
    </Button>
    <Button variant="danger" className="w-100 d-flex align-items-center">
      <FontAwesomeIcon icon={faGoogle} className="fs-4 text-white" />
      <span className="w-100 text-center text-white">Continue with Google</span>
    </Button>
  </Fragment>
);


const SignUpForm = () => {
  const navigate = useNavigate();
  const [validated, setValidated] = useState(false);
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [passwordMatchError, setPasswordMatchError] = useState(false);
  const [passwordFormatError, setPasswordFormatError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [lastError, setLastError] = useState(false);
  const [companyError, setCompanyError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/;
  const namePattern = /^[A-Za-z]{3,100}$/; // Only letters, min 3, max 100
  const companyPattern = /^[A-Za-z]{3,300}$/; // Only letters, min 3, max 300

  const [formData, setFormData] = useState({
    name: "",
    last: "",
    email: "",
    companyname: "",
    mobile: phone,
    password: password,
  });

  useEffect(() => {
    setFormData({ ...formData, mobile: phone, password: password });
  }, [phone, password]);

  const togglePasswordVisibility = () => setShowPassword(!showPassword);
  const toggleConfirmPasswordVisibility = () => setShowConfirmPassword(!showConfirmPassword);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    const form = event.currentTarget;

    let isValid = true; // Flag to check if form is valid

    // Check if form fields are valid
    if (form.checkValidity() === false) {
      event.stopPropagation();
      isValid = false;
    }

    // Check if passwords match
    if (formData.password !== confirmPassword) {
      setPasswordMatchError(true);
      isValid = false;
    } else {
      setPasswordMatchError(false);
    }

    // Check if password meets format requirements
    if (!passwordPattern.test(formData.password)) {
      setPasswordFormatError(true);
      isValid = false;
    } else {
      setPasswordFormatError(false);
    }

    // Check if phone number is provided
    if (formData.mobile === "") {
      setPhoneError(true);
      isValid = false;
    } else {
      setPhoneError(false);
    }

    // Validate name
    if (!namePattern.test(formData.name)) {
      setNameError(true);
      isValid = false;
    } else {
      setNameError(false);
    }

    // Validate last name
    if (!namePattern.test(formData.last)) {
      setLastError(true);
      isValid = false;
    } else {
      setLastError(false);
    }

    // Validate company name
    if (!companyPattern.test(formData.companyname)) {
      setCompanyError(true);
      isValid = false;
    } else {
      setCompanyError(false);
    }

    // Only proceed if the form is valid
    if (isValid) {
      const input = {
        name: `${formData.name} ${formData.last}`,
        email: formData.email,
        companyname: formData.companyname,
        mobile: formData.mobile,
        password: formData.password,
      };

      try {
        const response = await axios.post("https://lunarsenterprises.com:4002/brookscontainer/user/registeration", input, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        });

        if (response.data.result === false) {
          // Show error message from API
          toast.error(response.data.message);
        } else {
          // Handle successful registration
          toast.success(response.data.message);
          navigate("/Sign-In");
        }
      } catch (error) {
        console.error("Registration error: ", error);
        toast.error("An error occurred during registration.");
      }
    }

    setIsLoading(false);
    setValidated(true); // This should be set after all validations are passed
  };

  return (
    <Form validated={validated} onSubmit={handleSubmit}>
      <ToastContainer />
      <Row>
        <Col lg={6}>
          <Form.Group className="mb-4">
            <Form.Label>First Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Your First Name"
              name="name"
              onChange={handleInputChange}
              required
            />
            <Form.Control.Feedback type="invalid">
              First name is required.
            </Form.Control.Feedback>
            {nameError && (
              <div className="text-danger mt-1">First name must contain only letters (3-100 characters).</div>
            )}
          </Form.Group>
        </Col>
        <Col lg={6}>
          <Form.Group className="mb-4">
            <Form.Label>Last Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Your Last Name"
              name="last"
              onChange={handleInputChange}
              required
            />
            <Form.Control.Feedback type="invalid">
              Last name is required.
            </Form.Control.Feedback>
            {lastError && (
              <div className="text-danger mt-1">Last name must contain only letters (3-100 characters).</div>
            )}
          </Form.Group>
        </Col>
        <Col xs={12}>
          <Form.Group className="mb-4">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              placeholder="Your Email Address"
              name="email"
              onChange={handleInputChange}
              required
            />
            <Form.Control.Feedback type="invalid">
              Please provide a valid email.
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col xs={12}>
          <Form.Group className="mb-4">
            <Form.Label>Company Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Your Company Name"
              name="companyname"
              onChange={handleInputChange}
              required
            />
            <Form.Control.Feedback type="invalid">
              Please provide a valid company name.
            </Form.Control.Feedback>
            {companyError && (
              <div className="text-danger mt-1">Company name must contain only letters (3-300 characters).</div>
            )}
          </Form.Group>
        </Col>
        <Col lg={12}>
          <Form.Group className="mb-4">
            <Form.Label>Contact Number</Form.Label>
            <PhoneInput country={"us"} value={phone} onChange={setPhone} required />
            {phoneError && (
              <div className="text-danger mt-1">Please provide a valid phone number.</div>
            )}
          </Form.Group>
        </Col>
        <Col lg={6}>
          <Form.Group className="mb-4">
            <Form.Label>Password</Form.Label>
            <InputGroup>
              <Form.Control
                type={showPassword ? "text" : "password"}
                placeholder="Enter Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <Button variant="" className="secondary" onClick={togglePasswordVisibility}>
                <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
              </Button>
              <Form.Control.Feedback type="invalid">
                Password is required.
              </Form.Control.Feedback>
            </InputGroup>
            {passwordFormatError && (
              <div className="text-danger mt-1">
                Password must contain at least 6 characters, including uppercase, lowercase, a digit, and a special character.
              </div>
            )}
          </Form.Group>
        </Col>
        <Col lg={6}>
          <Form.Group className="mb-4">
            <Form.Label>Confirm Password</Form.Label>
            <InputGroup>
              <Form.Control
                type={showConfirmPassword ? "text" : "password"}
                placeholder="Confirm Password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
              />
              <Button variant="" className="secondary" onClick={toggleConfirmPasswordVisibility}>
                <FontAwesomeIcon icon={showConfirmPassword ? faEyeSlash : faEye} />
              </Button>
              <Form.Control.Feedback type="invalid">
                Please confirm your password.
              </Form.Control.Feedback>
            </InputGroup>
            {passwordMatchError && (
              <div className="text-danger mt-1">Passwords do not match.</div>
            )}
          </Form.Group>
        </Col>
      </Row>
      <Form.Group className="mb-4">
        <Form.Check
          type="checkbox"
          label={
            <>
              I accept the <p href="#!">terms & conditions</p>
              <p href="#!">privacy policy</p>
            </>
          }
          required
        />
        <Form.Control.Feedback type="invalid">
          You must accept the terms and conditions.
        </Form.Control.Feedback>
      </Form.Group>
      <Button variant="primary" type="submit" className="w-100" disabled={isLoading}>
        {isLoading ? <><Spinner animation="grow" size="sm" /> Signing Up...</> : <>Sign Up<FontAwesomeIcon icon={faArrowRight} className="ms-2 text-white" /></>}
      </Button>
    </Form>
  );
};




const SignUpFormCard = () => (
  <Card className="ezy__signup10-form-card">
    <Card.Body className="p-md-5">
      <h2 className="ezy__signup10-heading mb-3">Welcome to Easy Frontend</h2>
      <p className="mb-4 mb-md-5">
        <span className="mb-0 opacity-50 lh-1">Already have an account?</span>
        <Button variant="link" href="/Sign-In" className="py-0 text-dark text-decoration-none">
          Sign In
        </Button>
      </p>
      <SignUpForm />
    </Card.Body>
  </Card>
);

const SignUp = () => {
  return (
    <section className="ezy__signup10 light d-flex">
      <Container>
        <Row className="align-items-center">
          <Col md={6} lg={5}>
            <SignUpFormCard />
          </Col>
          <Col md={6} lg={7} className="d-none d-md-block">
            <img src={loginimage} alt="SignUp" className="w-100 h-auto" />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default SignUp;
