import React from "react";
import {
	Button,
	Card,
	Col,
	Container,
	Form,
	InputGroup,
	Row,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faFileInvoiceDollar,
	faTrashAlt,
	faMapMarkerAlt,
	faPhoneAlt,
	faEnvelope,
	faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";
import PropTypes from "prop-types";

import '../Checkout/Checkout.css';

import dummyimg from '../images/transport.jpg'


// BillingItem Component
const BillingItem = ({ bill, onEdit }) => {
	const [isEditing, setIsEditing] = React.useState(false);
	const [value, setValue] = React.useState(bill.value);

	const handleEdit = () => {
		onEdit({ ...bill, value });
		setIsEditing(false);
	};

	return (
		<div className="ezy__epcheckout6-content d-flex mb-3">
			<div>
				<FontAwesomeIcon icon={bill.icon} />
			</div>
			<div className="flex-grow-1 px-2">
				{isEditing ? (
					<InputGroup>
						<Form.Control
							value={value}
							onChange={(e) => setValue(e.target.value)}
						/>
						<Button variant="outline-primary" onClick={handleEdit}>
							Save
						</Button>
					</InputGroup>
				) : (
					<p className="mb-0">{bill.value}</p>
				)}
			</div>
			<div>
				<Button variant="link" onClick={() => setIsEditing(!isEditing)}>
					{isEditing ? "Cancel" : "Edit"}
				</Button>
			</div>
		</div>
	);
};

BillingItem.propTypes = {
	bill: PropTypes.object.isRequired,
	onEdit: PropTypes.func.isRequired,
};

const billingInfo = [
	{
		icon: faMapMarkerAlt,
		value: "Provati-73, East Pirmoholla, Amborkhana, Sylhet",
	},
	{
		icon: faFileInvoiceDollar,
		value: "Bill to the same address",
	},
	{
		icon: faPhoneAlt,
		value: "1742***080",
	},
	{
		icon: faEnvelope,
		value: "xyz@gmail.com",
	},
];

// Define orders array (dummy data for illustration)
const orders = [
	{
		img: dummyimg,
		title: "Product 1",
		color: "Red",
		country: "USA",
		bdPrice: 100,
	},
	// {
	// 	img: dummyimg,
	// 	title: "Product 2",
	// 	color: "Blue",
	// 	country: "China",
	// 	bdPrice: 200,
	// },
];

const Checkout = () => {
	const [billingDetails, setBillingDetails] = React.useState(billingInfo);

	const handleEditBillingDetail = (updatedBill) => {
		setBillingDetails((prevDetails) =>
			prevDetails.map((bill) =>
				bill.value === updatedBill.value ? updatedBill : bill
			)
		);
	};

	// PromoCode Component
	const PromoCode = () => (
		<div className="mt-4">
			<span className="ezy__epcheckout6-content mb-1">Promo Code</span>
			<InputGroup className="ezy__epcheckout6-promo-code">
				<Form.Control
					type="text"
					className="me-2 rounded-3"
					placeholder="Enter Promo Code"
				/>
				<Button variant="" className="ezy__epcheckout6-btn-outline h-auto rounded-3">
					Apply
				</Button>
			</InputGroup>
		</div>
	);

	// SideBar Component
	const SideBar = () => {
		return (
			<Card className="ezy__epcheckout6-card">
				<Card.Body className="p-md-4">
					<h6 className="fs-5 fw-bold mb-3">Shipping {"&"} Billing</h6>
					{billingDetails.map((bill, i) => (
						<BillingItem bill={bill} key={i} onEdit={handleEditBillingDetail} />
					))}
					<h6 className="fs-5 fw-bold mt-4 mb-3">Order Summary</h6>
					<div className="ezy__epcheckout6-content d-flex justify-content-between align-items-center mb-2">
						<span>Subtotal (2 Items)</span>
						<span>BDT 6,638.89</span>
					</div>
					<div className="ezy__epcheckout6-content d-flex justify-content-between align-items-center mb-2">
						<span>Shipping Fee</span>
						<span>BDT 110.00</span>
					</div>
			
					<hr className="ezy__epcheckout6-hr mb-3 mt-4" />
					<div className="d-flex justify-content-between align-items-center">
						<span className="fw-bold">Total</span>
						<span className="fs-4 fw-bold">US $1231.00</span>
					</div>
					<p className="ezy__epcheckout6-content text-end opacity-50 mb-0">
						VAT included, where applicable
					</p>
					<Button variant="" className="ezy__epcheckout6-btn w-100 mt-4 fs-6">
						Proceed to Pay
					</Button>
				</Card.Body>
			</Card>
		);
	};

	// Quantity Component
	const Quantity = () => {
		return (
			<InputGroup className="ezy__epcheckout6-qty mt-3">
				<Button variant="" className="px-3 py-0 d-inline-flex justify-content-center" type="button">
					-
				</Button>
				<Form.Control
					type="text"
					placeholder=""
					defaultValue="2"
					aria-label="Example text with button addon"
					aria-describedby="button-addon1"
				/>
				<Button variant="" className="px-3 py-0 d-inline-flex justify-content-center" type="button">
					+
				</Button>
			</InputGroup>
		);
	};

	// OrderItem Component
	const OrderItem = ({ item, index }) => {
		return (
			<Card className="ezy__epcheckout6-card mb-3">
				<Card.Body className="px-md-4">
					<Row>
						<Col lg={6}>
							<p className="ezy__epcheckout6-content mb-0">
								Package {index + 1} of {orders.length}
							</p>
						</Col>
						<Col lg={6} className="text-lg-end">
							<p className="ezy__epcheckout6-content mb-0">
								Shipped by <b>Md. Parves Hossain</b>
							</p>
						</Col>
					</Row>
				</Card.Body>
				<hr className="ezy__epcheckout6-hr my-0" />
				<Card.Body className="p-md-4">
					<Row>
						<Col lg={5}>
							<div className="ezy__epcheckout6-delivery-card d-flex rounded-3 mb-3 p-3">
								<div className="me-2">
									<FontAwesomeIcon icon={faCheckCircle} />
								</div>
								<div>
									<p className="fw-bold mb-2">BDT 55</p>
									<p className="ezy__epcheckout6-content opacity-75 mb-0">
										Home Delivery
									</p>
									<p className="ezy__epcheckout6-content opacity-75 mb-0">
										Estimated Delivery Time: 53-53 Days
									</p>
								</div>
							</div>
						</Col>
					</Row>
					<div className="d-flex align-items-start">
						<div className="ezy__epcheckout6-image me-3 me-md-4">
							<a href="#!">
								<img src={item.img} alt="" className="img-fluid" />
							</a>
						</div>
						<div>
							<div className="ezy__epcheckout6-heading mb-2">
								<a href="#!">{item.title}</a>
							</div>
							<p className="ezy__epcheckout6-content mb-3">
								<span className="me-3">
									<b>Color</b>: {item.color}
								</span>
								<span>
									<b>Ships From</b>: {item.country}
								</span>
							</p>
							<Quantity />
							<p className="mt-2 fs-6 fw-bold">BDT {item.bdPrice}.00</p>
						</div>
					</div>
				</Card.Body>
			</Card>
		);
	};

	return (
        <div className="margintop ">
		<Container className="mt-5  ">
			<Row>
				<Col md={6} className="mb-4 ">
					<h6 className="fs-5 fw-bold mb-4">Your Orders</h6>
					{orders.map((item, index) => (
						<OrderItem item={item} key={index} index={index} />
					))}
				</Col>
				<Col md={6}>
					<SideBar />
				</Col>
			</Row>
		</Container>
        </div>
	);
};

export default Checkout;
