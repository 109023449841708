import React, { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons"; // FontAwesome icons for toggle
import Overview from "./Overview";
import Order from "./Order";
import Agreement from "./Agreement";
import "../Profile/Profile.css";
import axios from "axios";

const sidebarData = [
  { type: "", title: "Overview" },
  { type: "divider" },
  // { type: "link", title: "Agreement" },
  { type: "", title: "Orders History" },

  { type: "divider" },
  { type: "link", title: "Log Out" },
];

const handleLogout = () => {
  // Clear local storage
  localStorage.clear(); // or localStorage.removeItem('your_key') to remove specific items

  // Optionally, redirect the user after logging out
  window.location.href = "/"; // Change this to your login page route
};

// Example for rendering the "Log Out" link
const navItem = { type: "link", title: "Log Out" };

const Profilemain = () => {
  const userid = localStorage.getItem("brooks_user_id");

  const [Profile, setProfile] = useState(null);

  const [editing, setEditing] = useState(false);
  const [updatedProfile, setUpdatedProfile] = useState({
    u_name: "",
    u_email: "",
    u_profile_pic: null,
  });
  const fileInputRef = useRef(null);

  const handleImageClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setUpdatedProfile((prev) => ({ ...prev, u_profile_pic: file }));
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUpdatedProfile((prev) => ({ ...prev, [name]: value }));
  };

  const handleSave = async () => {
    const formData = new FormData();

    formData.append("name", updatedProfile.u_name || Profile.u_name);
    formData.append("email", updatedProfile.u_email || Profile.u_email);
    if (updatedProfile.u_profile_pic) {
      formData.append("image", updatedProfile.u_profile_pic);
    }

    try {
      await axios.post(
        "https://lunarsenterprises.com:4002/brookscontainer/profile/edit",
        formData,
        {
          headers: {
            u_id: userid,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      Profileimg(); // Refresh profile data after updating
      setEditing(false); // Exit editing mode
    } catch (error) {
      console.error("Error updating profile:", error);
    }
  };

  const Profileimg = async () => {
    try {
      const response = await axios.post(
        "https://lunarsenterprises.com:4002/brookscontainer/profile/view",
        {},
        {
          headers: {
            u_id: userid,
          },
        }
      );
      setProfile(response.data.data[0]); // setProfile to a single object
    } catch (error) {
      console.error("Error fetching profile data:", error);
    }
  };

  useEffect(() => {
    Profileimg();
  }, []);

  const [selectedContent, setSelectedContent] = useState("Overview");
  const [isSidebarOpen, setIsSidebarOpen] = useState(false); // Sidebar toggle for mobile

  const handleSidebarItemClick = (title) => {
    setSelectedContent(title);
    setIsSidebarOpen(false); // Close sidebar on mobile after clicking an item
  };

  const renderContent = () => {
    switch (selectedContent) {
      case "Overview":
        return <Overview />;
      case "Orders History":
        return <Order />;
      case "Agreement":
        return <Agreement />;
      case "Log Out":
        return (
          <div>
            {navItem.type === "link" && (
              <button onClick={handleLogout} className="logout-button">
                {navItem.title}
              </button>
            )}
          </div>
        );
      default:
        return <div>Select an option from the sidebar.</div>;
    }
  };

  return (
    <section className=" p-5 bg-light text-dark pb-5">
      <div className="container">
        <div className="row">
          {/* Toggle button for mobile */}

          {/* Sidebar */}
          <div
            className={`col-md-4 ${
              isSidebarOpen ? "" : "d-none d-md-block"
            } margintopp`}
          >
            <div className="bg-white shadow py-4">
              <ul className="nav flex-column">
                {sidebarData.map((item, i) => {
                  if (item.type === "divider") {
                    return <hr className="my-2" key={i} />;
                  }

                  return (
                    <li
                      className={`nav-item px-3 py-2 ${
                        selectedContent === item.title &&
                        "active bg-light border-start border-primary"
                      }`}
                      key={i}
                      onClick={() => handleSidebarItemClick(item.title)}
                    >
                      <a href="#!" className="nav-linkname">
                        {item.title}
                      </a>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>

          {/* Profile content */}
          <div className="col-md-8 margintopp">
            <div className="col-12 d-md-none ">
              <button
                className="btn btn-primary w-10 mb-3"
                onClick={() => setIsSidebarOpen(!isSidebarOpen)}
              >
                {isSidebarOpen ? (
                  <FontAwesomeIcon icon={faTimes} /> // Close icon
                ) : (
                  <FontAwesomeIcon icon={faBars} /> // Menu icon
                )}
                {isSidebarOpen ? " Close Menu" : " Menu"}
              </button>
            </div>

            <div className="bg-white shadow p-4">
              <div>
                {Profile && (
                  <div className="d-flex align-items-center mb-5">
                    <img
                      src={
                        Profile.u_profile_pic
                         
                          ? `https://lunarsenterprises.com:4002/${Profile.u_profile_pic}`
                          : "https://cdn.easyfrontend.com/pictures/team/team_square_3.jpeg"
                      }
                      alt="User Profile"
                      className="rounded-circle"
                      // width="70"
                      // height="70"
                      
                      onClick={handleImageClick}
                      style={{ cursor: "pointer" }}
                    />
                  
                    {editing ? (
                      <div className="ms-3 mt-1">

<input
                      type="file"
                      ref={fileInputRef}
                      style={{ display: "none" }}
                      accept="image/*"
                      onChange={handleFileChange}
                    />
                        <input
                          type="text"
                          name="u_name"
                          value={updatedProfile.u_name || Profile.u_name}
                          onChange={handleInputChange}
                          placeholder="Enter Name"
                          className="form-control mb-2"
                        />
                        <input
                          type="email"
                          name="u_email"
                          value={updatedProfile.u_email || Profile.u_email}
                          onChange={handleInputChange}
                          placeholder="Enter Email"
                          className="form-control"
                        />
                        <button
                          className="btn btn-primary mt-2"
                          onClick={handleSave}
                        >
                          Save
                        </button>
                        <button
                          className="btn btn-secondary mt-2 ms-2"
                          onClick={() => setEditing(false)}
                        >
                          Cancel
                        </button>
                      </div>
                    ) : (
                      <div className="ms-3 mt-1">
                        <h6 className="ml-5 h5">
                          {Profile.u_name || "Default Name"}
                        </h6>
                        <h6 className="ml-5">
                          {Profile.u_email || "Default Email"}
                        </h6>
                        <div
                          className=" cursor-pointer  p1   btnsize btn-secondary mt-2"
                          onClick={() => setEditing(true)}
                        >
                          Edit Profile
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
              <div>{renderContent()}</div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Profilemain;
