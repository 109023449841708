import React, { Fragment, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  InputGroup,
  Modal,
  Row,
  Spinner,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRight,
  faEye,
  faEyeSlash,
} from "@fortawesome/free-solid-svg-icons";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import loginimage from "../../images/loginimg.jpg";
import "react-phone-input-2/lib/style.css";

import "../Sign-in/Signin.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const SignUpForm = () => {
  const [validated, setValidated] = useState(false);
  const [phone, setPhone] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const [isLoading, setisLoading] = useState("");

  const navigate = useNavigate();
  const togglePasswordVisibility = () => setShowPassword(!showPassword);

  const [formdata, setFormdata] = useState({ email: "", password: "" });

  const handlechange = (e) => {
    const { name, value } = e.target;
    setFormdata((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const form = event.currentTarget;
    if (form.checkValidity() === false || phone === "") {
      event.preventDefault();
      event.stopPropagation();
    }
    setisLoading(true);

    try {
      const response = await axios.post(
        "https://lunarsenterprises.com:4002/brookscontainer/login",
        formdata
      );
      console.log(response);
      const { result, message, user_token, user_id } = response.data;

      if (result) {
        toast.success(message);
        // Store user_token and user_id in local storage
        localStorage.setItem("brooks_token", user_token);
        localStorage.setItem("brooks_user_id", user_id);
        navigate("/");
      } else {
        toast.error(message || "Please try again.");
      }
    } catch (error) {
      console.error("Login error:", error);
      toast.error("An error occurred. Please try again.");
    }

    console.log(formdata, "formdataformdata");

    setisLoading(false);

    setValidated(true);
  };

  const [forgotPasswordEmail, setForgotPasswordEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isOtpSent, setIsOtpSent] = useState(false); // Track OTP status
  const [isOtpVerified, setIsOtpVerified] = useState(false); // Track OTP verification status
  const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false);

  const handleShowForgotPasswordModal = () => setShowForgotPasswordModal(true);
  const handleCloseForgotPasswordModal = () =>
    setShowForgotPasswordModal(false);
  // Password pattern
  const passwordPattern =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/;

  // Function to handle sending OTP
  const sendOtp = async () => {
    if (!forgotPasswordEmail) {
      toast.error("Please enter an email address.");
      return;
    }

    try {
      const response = await axios.post(
        "https://lunarsenterprises.com:4002/brookscontainer/mail-send",
        { email: forgotPasswordEmail, }
      );

      const  result = response.data.status;
      const  message = response.data.status;

      console.log(result);
      
      if (response.data.status ===true) {
        toast.success('OTP Send Email Please Check',message);
        setIsOtpSent(true); // Set OTP sent to true
      } else {
        toast.error(  "Error sending OTP.");
      }
    } catch (error) {
      console.error("Error sending OTP catch:", error);
      toast.error("An error occurred. Please try again.");
    }
  };

  // Function to handle OTP verification
  const verifyOtp = async () => {
    if (!otp) {
      toast.error("Please enter the OTP.");
      return;
    }

    try {
      const response = await axios.post(
        "https://lunarsenterprises.com:4002/brookscontainer/otp-verification",
        {
          email: forgotPasswordEmail,
          code: otp,
    
        }
      );

      const { result, message } = response.data;
      if (result) {
        toast.success("OTP verified successfully.");
        setIsOtpVerified(true); // Set OTP verified to true
      } else {
        toast.error(message || "Error verifying OTP.");
      }
    } catch (error) {
      console.error("Error verifying OTP:", error);
      toast.error("An error occurred. Please try again.");
    }
  };

  // Function to handle password reset
  const resetPassword = async () => {
    if (newPassword !== confirmPassword) {
      toast.error("Passwords do not match.");
      return;
    }

    if (!passwordPattern.test(newPassword)) {
      toast.error(
        "Password must be at least 6 characters and contain one uppercase, one lowercase, one number, and one special character."
      );
      return;
    }

    // Add your update password API call here
    try {
      const response = await axios.post(
        "https://lunarsenterprises.com:4002/brookscontainer/change-password",
        {
          email: forgotPasswordEmail,
        
          password: newPassword,
        }
      );

      const { result, message } = response.data;
      if (result) {
        toast.success("Password updated successfully.");
        handleCloseForgotPasswordModal(); // Close the modal after success
      } else {
        toast.error(message || "Error resetting password.");
      }
    } catch (error) {
      console.error("Error resetting password:", error);
      toast.error("An error occurred. Please try again.");
    }
  };

  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  return (
    <>
      <Modal
        show={showForgotPasswordModal}
        onHide={handleCloseForgotPasswordModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>Forgot Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Label>Email Address</Form.Label>
            <Form.Group className="d-flex mb-3">
              <Form.Control
                type="email"
                placeholder="Enter Email Address"
                className="me-2"
                value={forgotPasswordEmail}
                onChange={(e) => setForgotPasswordEmail(e.target.value)}
                required
                disabled={isOtpSent} // Disable email input after OTP is sent
              />
              <Button
                variant="primary"
                type="button"
                className="flex-shrink-0"
                onClick={sendOtp}
                disabled={isOtpSent} // Disable button after OTP is sent
              >
                {isOtpSent ? "OTP Sent" : "Send OTP"}
              </Button>
            </Form.Group>

            {isOtpSent && !isOtpVerified && (
              <>
                <Form.Group className="d-flex mb-3">
                  <Form.Control
                    type="text"
                    placeholder="Enter OTP"
                    className="me-2"
                    value={otp}
                    onChange={(e) => setOtp(e.target.value)}
                    required
                  />
                  <Button
                    variant="secondary"
                    type="button"
                    className="flex-shrink-0"
                    onClick={verifyOtp} // Handle OTP verification
                  >
                    Verify OTP
                  </Button>
                </Form.Group>
              </>
            )}

            {isOtpVerified && (
              <>
                <Form.Group className="mb-3">
        <Form.Label>New Password</Form.Label>
        <div className="input-group">
          <Form.Control
            type={showNewPassword ? 'text' : 'password'}
            placeholder="Enter New Password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            required
          />
          <span
            className="input-group-text"
            onClick={() => setShowNewPassword(!showNewPassword)}
            style={{ cursor: 'pointer' }}
          >
            <FontAwesomeIcon icon={showNewPassword ? faEye : faEyeSlash} />
          </span>
        </div>
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>Confirm Password</Form.Label>
        <div className="input-group">
          <Form.Control
            type={showConfirmPassword ? 'text' : 'password'}
            placeholder="Confirm New Password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
          />
          <span
            className="input-group-text"
            onClick={() => setShowConfirmPassword(!showConfirmPassword)}
            style={{ cursor: 'pointer' }}
          >
            <FontAwesomeIcon icon={showConfirmPassword ? faEye : faEyeSlash} />
          </span>
        </div>
      </Form.Group>

                <Button
                  variant="primary"
                  type="button"
                  className="w-100"
                  onClick={resetPassword} // Handle reset password
                >
                  Reset Password
                </Button>
              </>
            )}
          </Form>
        </Modal.Body>
      </Modal>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Row>
          <Col xs={12}>
            <Form.Group className="mb-4">
              <Form.Label htmlFor="signup10-email" className="mb-2">
                Email
              </Form.Label>
              <Form.Control
                type="email"
                id="signup10-email"
                placeholder="Your Email Address"
                required
                name="email"
                onChange={handlechange}
              />
              <Form.Control.Feedback type="invalid">
                Please provide a valid email.
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col lg={12}>
            <Form.Group className="mb-4">
              <Form.Label htmlFor="signup10-password" className="mb-2">
                Password
              </Form.Label>
              <InputGroup>
                <Form.Control
                  type={showPassword ? "text" : "password"}
                  id="signup10-password"
                  placeholder="Enter Password"
                  name="password"
                  onChange={handlechange}
                  required
                />
                <Button
                  variant=""
                  className="secondary"
                  onClick={togglePasswordVisibility}
                >
                  <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                </Button>
                <Form.Control.Feedback type="invalid">
                  Password is required.
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
          </Col>
        </Row>

        <Button
          variant="primary"
          type="submit"
          className="ezy__signup10-btn-submit w-100"
          disabled={isLoading}
        >
          {isLoading ? (
            <>
              <Spinner animation="grow" size="sm" /> Log In...
            </>
          ) : (
            <>
              Log In
              <FontAwesomeIcon
                icon={faArrowRight}
                className="ms-2 text-white"
              />
            </>
          )}
        </Button>
        <Button
          variant="primary"
          // type="submit"
          className="ezy__signup10-btn-submit w-100 mt-3"
          onClick={handleShowForgotPasswordModal}
        >
          Forgot your password?
        </Button>
        <p className="mt-2">
          <a
            href="/"
            className="underline-link align-items-end d-flex justify-content-center"
          >
            Click to Home ?
          </a>
        </p>
      </Form>
    </>
  );
};

const SignUpFormCard = () => (
  <Card className="ezy__signup10-form-card">
    <Card.Body className="p-md-5">
      <h2 className="ezy__signup10-heading mb-3">
        Welcome to Brooks Container
      </h2>

      <SignUpForm />

      {/* <div className="position-relative ezy__signup10-or-separator">
				<hr className="my-4 my-md-5" />
				<span className="px-2">Or</span>
			</div> */}
    </Card.Body>
  </Card>
);

const Signin = () => {
  const [isSignIn, setIsSignIn] = useState(false);

  const toggleForm = () => {
    setIsSignIn((prev) => !prev);
  };

  return (
    <section className="ezy__signup10 light d-flex">
      <ToastContainer />
      <Container>
        <Row className="justify-content-between h-100">
          <Col
            md={4}
            lg={6}
            className={`image-container ${isSignIn ? "sign-in-active" : ""}`}
          >
            <div
              className="ezy__signup10-bg-holder d-none d-md-block h-100"
              style={{
                backgroundImage: `url(${loginimage})`,
              }}
            />
          </Col>
          <Col md={8} lg={6} className="py-5">
            <Row className="align-items-center h-100 px-xl-5">
              <Col
                xs={12}
                className={`form-container ${isSignIn ? "sign-in-active" : ""}`}
              >
                <SignUpFormCard isSignIn={isSignIn} toggleForm={toggleForm} />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Signin;
