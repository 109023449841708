import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faFire, faCogs } from "@fortawesome/free-solid-svg-icons";
import "../Profile/Profile.css";
import imagedummy from "../images/loginsign.jpg";
import axios from "axios";
import { Button } from "react-bootstrap";

const sidebarData = [
  { icon: faUser, title: "Profile" },
  { icon: faFire, title: "Order Stories" },
  { icon: faCogs, title: "Setting" },
];

const SideBar = () => {
  const [activeTab, setActiveTab] = useState(1);
  const handleActiveTab = (index) => setActiveTab(index);

  return (
    <div className="bg-white shadow rounded-3 p-3">
      {sidebarData.map((item, i) => (
        <div
          key={i}
          className={`text-center py-3 cursor-pointer ${
            activeTab === i
              ? "bg-primary text-white rounded"
              : "hover:bg-primary hover:text-white rounded"
          }`}
          onClick={() => handleActiveTab(i)}
        >
          <div className="h1">
            <FontAwesomeIcon icon={item.icon} />
          </div>
          <p className="h5 font-weight-bold mt-2">{item.title}</p>
        </div>
      ))}
    </div>
  );
};

const Order = () => {
  const [orders, setOrders] = useState([]);
  const userId = localStorage.getItem("brooks_user_id");

  useEffect(() => {
    fetchOrders();
  }, []);

  const fetchOrders = async () => {
    try {
      const response = await axios.post(
        "https://lunarsenterprises.com:4002/brookscontainer/user/order/history",
        {},
        {
          headers: { u_id: userId },
        }
      );
      setOrders(response.data.data);

      console.log(response.data.data);
      
    } catch (error) {
      console.error("Error fetching orders:", error);
    }
  };

  return (
    <section className="text-dark">
      <div className="row">
        <div className="col-md-12">
          <div className="bg-white shadow rounded-3 p-4">
            <h3 className="h4 font-weight-bold">Order History</h3>
          </div>
          {orders.length > 0 ? (
            orders.map((order, index) => (
              <div key={index} className="bg-white shadow rounded-3 p-4 mt-3">
                <div className="row align-items-center">
                  <div className="col-lg-3 text-center">
                    <img
                      src={
                        order.o_profile_pic
                          ? `https://lunarsenterprises.com:4002/${order.u_profile_pic}`
                          : imagedummy
                      }
                      alt="User Profile"
                      className="orderimage"
                      style={{ cursor: "pointer" }}
                    />
                  </div>
                  <div className="col-lg-5">
                    <h6 className="h5 font-weight-bold mt-2">
                      {order.o_container_name || "Container"}
                    </h6>
                  </div>
                </div>

                {/* Display container details for each order */}
                {/* Display container details for each order */}
                {order.container_details?.map((container, idx) => (
                  <div key={idx} className="mt-3 p-3 bg-light rounded">
                    <div className="row">
                      <div className="col-4">
                        <p>
                          <strong>Size:</strong> {container.oc_size} ft
                        </p>
                      </div>
                      <div className="col-4">
                        <p>
                          <strong>Quantity:</strong> {container.oc_quantity}
                        </p>
                      </div>
                      <div className="col-4">
                        <p>
                          <strong>Price:</strong> ${container.oc_amount}
                        </p>
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-4">
                        <p>
                          <strong>Specification:</strong> {container.oc_body_type} 
                        </p>
                      </div>
                      <div className="col-4">
                        <p>
                          <strong>Condition:</strong> {container.oc_condition}
                        </p>
                      </div>
                      <div className="col-4">
                        <p>
                          {/* <strong>Price:</strong> ${container.oc_amount} */}
                        </p>
                      </div>
                    </div>

                    <div>
  {order.o_agreement ? (
    <a 
      href={`https://lunarsenterprises.com:4002/${order.o_agreement}`} 
      download 
      target="_blank" 
      rel="noopener noreferrer"
    >
      <Button>
        
      Download Agreement</Button>
    </a>
  ) : (
    <Button title="No Agreement Available" disabled >
      No Agreement Available </Button>

  )}
</div>



                  </div>
                ))}
              </div>
            ))
          ) : (
            <p className="text-center mt-4 h5 text-cyan-800">
              No Order History
            </p>
          )}
        </div>
      </div>
    </section>
  );
};

export default Order;
