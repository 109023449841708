import React, { useState } from 'react';
import { Button, Form, Container, Row, Col } from 'react-bootstrap';
import axios from 'axios';
import FileSaver from 'file-saver';
import '../Profile/Agreement.css'

const Agreement = () => {
  const [file, setFile] = useState(null);
  const [agreementUrl, setAgreementUrl] = useState('https://example.com/sample-agreement.pdf'); // Replace with actual URL

  // Handle file selection for upload
  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  // Upload the selected file
  const handleFileUpload = async () => {
    if (!file) {
      alert('Please select a file first.');
      return;
    }

    const formData = new FormData();
    formData.append('agreement', file);

    try {
      const response = await axios.post('https://your-upload-url.com/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      alert('File uploaded successfully!');
      console.log('Uploaded file response:', response.data);
    } catch (error) {
      console.error('Error uploading file:', error);
      alert('Failed to upload file.');
    }
  };

  // Download the agreement
  const handleDownload = () => {
    FileSaver.saveAs(agreementUrl, 'agreement.pdf');
  };

  return (
    <Container className="mt-5">
      <h2 className="text-center">Agreement Page</h2>
      <Row className="my-4">
        <Col md={6}>
          {/* View Agreement Section */}
          <h4>View Agreement</h4>
          <iframe
            src={agreementUrl}
            title="Agreement"
            width="100%"
            height="400px"
            style={{ border: '1px solid #ccc', marginBottom: '20px' }}
          />
        </Col>
        <Col md={6}>
          {/* Download Agreement Section */}
          <h4>Download Agreement</h4>
          <Button variant="primary" onClick={handleDownload}>
            Download Agreement
          </Button>
        </Col>
      </Row>

      <Row className="my-4">
        <Col md={6}>
          {/* Upload Agreement Section */}
          <h4>Upload Signed Agreement</h4>
          <Form>
            <Form.Group controlId="formFile" className="mb-3">
              <Form.Label>Select a file to upload</Form.Label>
              <Form.Control type="file" onChange={handleFileChange} />
            </Form.Group>
           
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default Agreement;
