import React from "react";
import "../Footer/Footer.scss";
import Logo from "../../Pages/images/png brooks 1 white png.png";
import { Image } from "react-bootstrap";
import { faXTwitter } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBuilding } from "@fortawesome/free-regular-svg-icons";
function Footer() {
  return (
    <footer class="ezy__footer18 dark-gray">
      <div class="container">
        <div class="row text-center text-sm-start mb-md-5">
          <div class="col-lg-3">
            <Image src={Logo} alt="image" className="imagestyle" />
            {/* <h2 class="fw-bold">Easy Frontend</h2> */}
            <p class="ezy__footer18-text opacity-75 mt-4 pe-lg-4">
              Providing quality container solutions for Buy/Rent and Lease
            </p>
          </div>
          <div class="col-sm-6 col-lg-2 mt-4 mt-lg-0">
            <h5>Quick Links</h5>
            <ul class="nav flex-column ezy__footer18-quick-links">
              <li>
                <a href="/"> Home</a>
              </li>
              
              <li>
                <a href="/Productlist"> Services</a>
              </li>
              <li>
                <a href="/contact-us">Contact Us</a>
              </li>
              {/* <li>
                <a href="/Sign-In">Login</a>
              </li> */}
            </ul>
          </div>
          <div class="col-sm-6 col-lg-2 mt-4 mt-lg-0">
            <h5>Social Media</h5>
            <ul class="nav flex-column ezy__footer18-quick-links">
              <li>
                <a href="https://www.facebook.com/brookscontainers/">
                  <i class="fab fa-facebook me-2"></i> Facebook
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/brooks_containers/
"
                >
                  <i class="fab fa-instagram me-2"></i> Instagram
                </a>
              </li>
              <li>
                <a href="https://www.linkedin.com/in/brooks-containers/">
                  <i class="fab fa-linkedin me-2"></i> LinkedIn
                </a>
              </li>
              <li>
                <a href="https://x.com/BrooksContainer">
                  <FontAwesomeIcon icon={faXTwitter} /> Twitter
                </a>
              </li>
            </ul>
          </div>
          <div class="col-sm-6 col-lg-2 mt-4 mt-lg-0">
            <h5>Services </h5>
            <ul class="nav flex-column ezy__footer18-quick-links">
              <li>
                <a href="/Productlist">Fixed Rental Services</a>
              </li>
              <li>
                <a href="/Productlist">Flexible Rental Services</a>
              </li>
            
            </ul>
          </div>
          <div class="col-sm-6 col-lg-3 mt-4 mt-lg-0">
            <h5>Contact Us</h5>
            <ul class="nav flex-column ezy__footer18-quick-links">
              <li>
                <a href="/contact-us">
                  <span class=" me-2 small opacity-50">
                    <FontAwesomeIcon icon={faBuilding} />{" "}
                  </span>{" "}
                  
                  Address Building A2 IFZA Business Park - Dubai Silicon
                  Oasis - Dubai
                </a>
              </li>
              <li>
                <a href="mailto:contact@brookcontainer.com">
                  <span class="fas fa-envelope me-2 small opacity-50"></span>
                  contact@brookscontainer.com

                </a>
              </li>
              <li>
                <a href="mailto:info@brookscontainer.com">
                  <span class="fas fa-envelope me-2 small opacity-50"></span>
                  info@brookscontainer.com


                </a>
              </li>
              {/* <li>
                <a href="tel:1633-154215">
                  <span class="fas fa-phone me-2 small opacity-50"></span> +880
                  1633-154215
                </a>
              </li> */}
            </ul>
          </div>
        </div>
        <hr />
        <div class="row d-flex justify-content-between align-items-center text-center text-lg-start">
          <div class="col-md-12">
            <p class=" mt-1 opacity-75 text-center">
              Copyright &copy; 2024 brookscontainer, All rights reserved
            </p>
          </div>
          {/* <div class="col-md-6">
            <ul class="ezy__footer18-nav nav justify-content-center justify-content-md-end mt-1">
              <li class="nav-item opacity-75">
                <a class="nav-link" href="#!">
                  Privacy
                </a>
              </li>
              <li class="nav-item opacity-75">
                <a class="nav-link" href="#!">
                  Security
                </a>
              </li>
              <li class="nav-item opacity-75">
                <a class="nav-link" href="#!">
                  Terms
                </a>
              </li>
            </ul>
          </div> */}
        </div>
      </div>
    </footer>
  );
}

export default Footer;
