import './App.css'
import Home from "./Components/Home/Home";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import Service from './Pages/Services/Products';
import About from './Pages/About/About';
import Contact from './Pages/Contact/Contact';
import Header from './Components/Header/Header';
import Footer from './Components/Footer/Footer';
import 'bootstrap/dist/css/bootstrap.min.css';
import Productlist from './Pages/Productslist/Productlist';
import Selectcontainer from './Pages/Selectcontainer/Selectcontainer';
import Checkout from './Pages/Checkout/Checkout';
import HttpCodes from './Pages/Errorpage/Errorpage';
import Rentalcontainer from './Pages/Selectcontainer/Rentalcontainer';
import Howtowork from './Pages/Howtowork/Howtowork';
import SignUp from './Pages/Login/Login';
import Signin from './Pages/Login/Sign-in/Signin';
import OurContainer from './Pages/OurContainer/OurContainer';
import Profilemain from './Pages/Profile/Profile';
import Insightnews from './Pages/Insightnews/Insightnews';

function AppContent() {
  const location = useLocation(); // Now inside BrowserRouter context
  

  const hideHeaderFooter = location.pathname === '/Sign-Up' || location.pathname === '/Sign-In';


  return (
    <>
  
      {!hideHeaderFooter && <Header />}

      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/Productlist' element={<Productlist />} />
        <Route path='/Selected-container/:c_id' element={<Selectcontainer />} />
        {/* <Route path='/Rental-container/:c_id' element={<Rentalcontainer />} /> */}
        <Route path='/about-us' element={<About />} />
        <Route path='/contact-us' element={<Contact />} />
        <Route path='/Sign-Up' element={<SignUp/>} />

        <Route path='/Sign-In' element={<Signin/>} />
        <Route path='/Checkout' element={<Checkout />} />
        <Route path='/how-to-work' element={<Howtowork />} />
        <Route path='/Our-Container' element={<OurContainer />} />
        <Route path='/Profile' element={<Profilemain />} />
        <Route path='/Insightnews' element={<Insightnews />} />


        <Route path="*" element={<HttpCodes />} />
      </Routes>
      
      {/* Conditionally render Footer */}
      {!hideHeaderFooter && <Footer />}
    </>
  );
}

function App() {
  return (
    <BrowserRouter>
      <AppContent /> {/* Now wrapped inside BrowserRouter */}
    </BrowserRouter>
  );
}

export default App;
