import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import classNames from "classnames";
import "../OurContainer/OurContainer.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const OurContainer = () => {
  const [containerImg, setContainerImg] = useState([]);
  const [categories, setCategories] = useState([{ label: "All", value: "" }]);
  const [activeCategory, setActiveCategory] = useState("");

  // Fetch containers and set categories dynamically
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(
          "https://lunarsenterprises.com:4002/brookscontainer/container/list",
          { c_body_type: "" }
        );
        const data = response.data.data;

        // Set container data
        setContainerImg(data);

        // Get unique c_name values for dynamic categories
        const uniqueCategories = [
          { label: "All", value: "" },
          ...Array.from(new Set(data.map((container) => container.c_name))).map(
            (name) => ({ label: name, value: name })
          ),
        ];
        setCategories(uniqueCategories);
      } catch (error) {
        console.error("Error fetching data", error);
      }
    };
    fetchData();
  }, []);

  // Fetch data based on active category selection
  useEffect(() => {
    const fetchCategoryData = async () => {
      try {
        const body = { c_body_type: activeCategory || "" };
        const response = await axios.post(
          "https://lunarsenterprises.com:4002/brookscontainer/container/list",
          body
        );
        setContainerImg(response.data.data);
      } catch (error) {
        console.error("Error fetching category data", error);
      }
    };
    fetchCategoryData();
  }, [activeCategory]);

  const handleCategoryClick = (value) => {
    setActiveCategory(value);
  };

  const handleclick = (c_id) => {
    navi(`/Selected-container/${c_id}`);

    console.log(c_id);
  };

  const navi = useNavigate();


  return (
    <section className="ezy__portfolio1 light">
      <Container>
        <Row className="justify-content-center mb-4 mb-md-5">
          <Col lg={6} className="text-center">
            <p className="ezy__portfolio1-sub-heading mb-2">THIS IS WHAT WE DO</p>
            <h2 className="ezy__portfolio1-heading mb-4">Our Containers</h2>
          </Col>
          <Col md={12} className="text-center mt-4">
            {/* Dynamic Category Buttons */}
            {categories.map((category, i) => (
              <Button
                variant=""
                className={classNames("m-1 ezy__portfolio1-btn-filter", {
                  active: activeCategory === category.value,
                })}
                key={i}
                onClick={() => handleCategoryClick(category.value)}
              >
                {category.label}
              </Button>
            ))}
          </Col>
        </Row>

        {/* Show Active Category Button Above List */}
        {/* {activeCategory && (
          <Row className="justify-content-center">
            <Button
              variant="primary"
              className="m-2"
              onClick={() => setActiveCategory("")} // Reset to show all
            >
              {categories.find((cat) => cat.value === activeCategory)?.label || "All"}
            </Button>
          </Row>
        )} */}

        {/* Container List */}
        <Row>
          {containerImg?.map((container, i) => (
            <Col md={6} lg={4} key={i}>
              <div className="ezy__portfolio1-item position-relative mt-4"
               onClick={() => handleclick(container.c_id)}
              >
                <img
                  src={`https://lunarsenterprises.com:4002/${container.image}`}
                  alt={container.c_name}
                  className="img-fluid w-100 prdt-img"
                />
                <div className="ezy__portfolio1-content text-center p-4">
                  <h5>{container.c_name}</h5>
                  <p className="price-container">
                          <span className="price-label">Brand New Price: </span>${" "}
                          {container.brand_new_price}
                        </p>

                        <p className="price-container">
                          <span className="price-label">Rental Price/Year: </span>${" "}

                          {container.rental_cost}
                        </p>
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
};

export default OurContainer;
